import axios from "axios";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiUsers = {
  async getUsers({limit, filter, paginationToken}) {
    this.apiIsLoading = true;
    let users;
    let result = await axios.get(`${apiUrl}/auth/users/users.json`, {
      params: {
        limit, filter, paginationToken
      }
    });
    users = result.data;
    this.apiIsLoading = false;
    return users;
  },
  async getUserById(userId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/users/${userId}/user.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPropertiesByUser(userId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/users/${userId}/properties/property.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPropertiesUnassignedToUser(userId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/users/${userId}/unassigned/property.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getHomeDashboardByUserId(userId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/users/${userId}/dashboard.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getSystemUsers() {
    this.apiIsLoading = true;
    let users;
    let result = await axios.get(`${apiUrl}/auth/users/system/users.json`,);
    users = result.data;
    this.apiIsLoading = false;
    return users;
  },
};
