import moment from 'moment';

const formatter = {
    dateFormat(date) {
      return moment(date).format("ll");
    },
    dateTimeFormat(date) {
      return moment(date).format("lll");
    },
    dateMonthFormat(date) {
      return moment(date).format("MMM YYYY");
    },
  
    toLocale(value) {
      if (!value) {
        return value;
      }
      return value.toLocaleString();
    },
  
    toFixedLocale(value) {
      return value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  
    toCurrencyUSD(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  
    toPercentage(value) {
      if (typeof value !== "number") {
        return value;
      }
      return value * 100 + " %";
    },
  }

  export default formatter;