<template>

  <v-app>

    <v-app-bar
      scroll-behavior="hide"
      height="45"
      extension-height="20"
      elevation="1"
      id="main-app-bar"
      class="px-0"
      color="#e0e0e0"

    >
      <v-app-bar-nav-icon
        v-if="user.adminId"
        @click.stop="toggleAdminDrawer"
      ></v-app-bar-nav-icon>

      <img
        style="max-width:100px; margin-left: 10px;"
        class="prodLogo d-md-block d-none mr-3"
        src="@/assets/logo-h-revvim.svg"
        alt="Revvim"
      />

      <v-divider vertical />


      <div class="app-breadcrumb-spacing">
      <Breadcrumb />
      </div>

      <v-spacer></v-spacer>

      <top-nav :is-partner-nav="isNonAdminPartner"></top-nav>

      <!-- Global banner -->
      <template v-slot:extension v-if="showGlobalBanner">
        <v-alert
          dark
          density="compact"
          tile
          width="100%"
          class="pt-5 mb-0 text-center"
          :class="themeClass.bar"
        >
          {{ globalBannerMessage }}
          <a class="text-white" href="mailto:support@revvim.com?subject=Website Support Request">Email support</a>
        </v-alert>
      </template>
    </v-app-bar>

    <v-main :class="themeClass.wrapper">
      <div class="app-top-spacing">
        <router-view :key="$store.locale" />
          <admin-menu v-if="user.adminId" :adminDrawer="adminDrawer" @nav="onNav"></admin-menu>
      </div>
    </v-main>

    <v-snackbar
      v-model="showSnackbar"
      :timeout="snackbarSettings.timeout"
      :color="snackbarSettings.color"
      :location="snackbarSettings.pos"
      :variant="snackbarSettings.variant"
    >
      <span v-html="snackbarSettings.text"></span>
      <template v-slot:actions="{ attrs }">
        <v-btn variant="text" @click="closeSnackbar" v-bind="attrs">
           {{ snackbarSettings.buttonText }}
        </v-btn>
      </template>
    </v-snackbar>

    <AppFooter />


  </v-app>

</template>

<style lang="scss">
.app-top-spacing {
  padding-top: 0px;
}
.app-breadcrumb-spacing {
  padding-top: 14px;
}
.negate-app-top-spacing {
  margin-top: -20px;
}
.prodLogo {
  max-width: 100%;
  width: 230px;
  display: block;
}

.prodLogo-m {
  height: 60px;
  width: auto;
}

#app {
  //font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #0071bc;
    }
  }
}

#main-app-bar .v-toolbar__extension {
  padding: 0;
}

.no-text-transform {
  letter-spacing: 0;
  text-transform: none;
}

.v-snackbar__content a {
  color: inherit;
}
</style>

<script>
import { defineAsyncComponent } from 'vue';
import TopNav from "@/components/TopNav.vue";
import { mapState } from 'pinia';
import { useAppStore } from '@/stores/app.ts';
import { EventBus } from "@/event.js";
import prodNames from "@/util/prodNames.js";
import Breadcrumb from "@/views/Breadcrumb.vue";

const AdminMenu = defineAsyncComponent(() => import("@/views/admin/AdminMenu"))

export default {
  name: "App",
  data() {
    return {
      showErrorAlert: false,
      showSnackbar: false,
      showGlobalBanner: false,
      globalBannerMessage: 'Global message',
      snackbarDefaults: {
        timeout: 5000,
        text: "",
        color: "deep-purple",
        pos: "bottom",
        variant: "flat"
      },
      snackbarSettings: {},
      prodName: "Revvim",
      adminDrawer: null,
      adminPath: false
    };
  },
  components: { TopNav, AdminMenu, Breadcrumb },
  computed: {
    ...mapState(useAppStore, ["user"]),
    isImpersonation() {
      if (this.user.adminId && this.user.adminId !== this.user.userId) {
        return true;
      }
      return false;
    },
    homeLink() {
      if (this.user && this.user.signedIn) {
        return "/app/";
      } else {
        return "/";
      }
    },
    themeClass() {
      return { bar: "bg-grey", wrapper: "bg-grey-lighten-3" };
    },
    isNonAdminPartner() {
      return this.hasPermission("Partner") && !this.user.adminId;
    },
    isProductMenuActive() {
      return this.user.signedIn && !this.isNonAdminPartner;
    },
    userGroups() {
      return this.user.groups;
    },
    closeSnackbarText() {
      return this.$t('general.close');
    }
  },
  watch: {
    $route(route) {
      if (route.meta.prodName) {
        this.prodName = route.meta.prodName;
      }
      this.adminPath = route.fullPath.startsWith("/app/admin");
    }
  },
  methods: {
    reload() {
      location.reload(true);
    },
    setSnackbarSettings(settings) {
      this.snackbarSettings = { ...this.snackbarDefaults, ...settings }
      if(!settings.buttonText){
        this.snackbarSettings.buttonText = this.closeSnackbarText;
      }
    },
    onNav() {
      this.adminDrawer = false;
    },
    hasPermission: function(permission) {
      return this.$store.hasPermission(permission);
    },
    closeSnackbar(){
      if(this.snackbarSettings.buttonAction){
        this.snackbarSettings.buttonAction();
      }
      this.showSnackbar = false;
    },
    toggleAdminDrawer(){
      this.adminDrawer = !this.adminDrawer;
    }
  },
  beforeMount() {
    this.snackbarSettings = Object.assign({}, this.snackbarDefaults);
  },
  mounted() {
    EventBus.$on("snackbar", settings => {
      this.setSnackbarSettings(settings);
      this.showSnackbar = true;
    });
  }
};
</script>
