import axios from "axios";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiLeads = {
  async getPipeDriveLeads() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/pipedrive/leads/leads.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPipeDriveLeadById(id) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/pipedrive/leads/${id}/lead.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  }
}
