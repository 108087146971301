import axios from "axios";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiProduction = {
  async getProductionPeriods() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/production/periods.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getProductionPeriodDeals(yearMonth) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/production/${yearMonth}/deals.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getProductionPeriodClients(yearMonth) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/production/${yearMonth}/clients.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getProductionPeriodProperties(yearMonth) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/production/${yearMonth}/properties.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getProductionPeriodPartner(yearMonth) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/production/${yearMonth}/partner.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getProductionForPeriodForDeal(yearMonth, dealId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/production/${yearMonth}/deals/${dealId}/production.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getProductionForPeriodForClient(yearMonth, clientId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/production/${yearMonth}/clients/${clientId}/production.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },

  async getFraudAiProductionPeriods() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/production/fraudAi/periods.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getFraudAiProductionPeriodClients(yearMonth) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/production/fraudAi/${yearMonth}/clients.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getProductionPeriodPropertiesForFraudAi(yearMonth) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/production/fraudAi/${yearMonth}/properties.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getFraudAiProductionWeeklyReportingPeriods() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/production/fraudAi/weekly/periods.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getFraudAiProductionWeeklyReportByClientId(clientId, startDate, endDate) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/production/fraudAi/weekly/client/${clientId}/report.json?startDate=${startDate}&endDate=${endDate}`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },

  async getReactProductionPeriods() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/production/react/periods.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getReactProductionPeriodClients(yearMonth) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/production/react/${yearMonth}/clients.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getProductionPeriodPropertiesForReact(yearMonth) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/production/react/${yearMonth}/properties.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  }
};
