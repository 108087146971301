import axios from "axios";
import {EventBus} from "@/event";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiCommissions = {
  async getCommissions() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/commissions/commissions.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getCommissionById(commissionId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/commissions/${commissionId}/commission.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getCommissionsForPartner(partnerId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/commissions/partner/${partnerId}/commissions.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getCommissionsByClientPaymentId(paymentId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/commissions/client/payments/${paymentId}/commissions.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getUnpaidCommissionsForPartner(partnerId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/commissions/partner/${partnerId}/unpaid/commissions.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async createCommission(commission) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/commissions/commission.json`,
      commission
    );
    EventBus.$emit("snackbar", {text: "Commission Created"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateCommission(commissionId, commission) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/commissions/${commissionId}/commission.json`,
      commission
    );
    EventBus.$emit("snackbar", {text: "Commission Updated"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async deleteCommissionById(commissionId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.delete(
      `${apiUrl}/auth/commissions/${commissionId}/commission.json`
    );
    EventBus.$emit("snackbar", {text: "Commission Deleted"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  }
};
