import prodNames from "@/util/prodNames";

export const Partner = [
  {
    path: "/app/sales/partners/:partnerId/payments/:paymentId",
    name: "app.sales.partner.payment.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'sales',
      permission: ["admin-partner", "admin-partner-read"],
      breadCrumb(route) {
        const partnerId = route.params.partnerId;
        const paymentId = route.params.paymentId;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Sales",
            to: { name: "app.sales" },
          },
          {
            text: "Partners",
            to: { name: "app.sales.partner" },
          },
          {
            text: partnerId,
            to: { name: "app.sales.partner.detail" },
          },
          {
            text: "Payments",
            to: { name: "app.sales.partner.detail" },
          },
          {
            text: paymentId,
            to: { name: "app.sales.partner.payment.detail" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-partners-payments-detail" */ "@/views/accounting/partners/payment/PaymentDetail"
      ),
  },
  {
    path: "/app/sales/partners/:partnerId/view/:view",
    name: "app.sales.partner.detail.view",
    meta: {
      requiresAuth: true,
      department: 'sales',
      prodName: prodNames.REVVIM,
      permission: ["admin-partner", "admin-partner-read"],
      breadCrumb(route) {
        const partnerId = route?.params?.partnerId;
        const view = route?.params?.view;
        return [
          {
            text: "Home",
            to: {
              name: "app-home",
            },
          },
          {
            text: "Sales",
            to: {
              name: "app.sales",
            },
          },
          {
            text: "Partners",
            to: {
              name: "app.sales.partner",
            },
          },
          {
            text: partnerId,
            to: {
              name: "app.sales.partner.detail",
            }
          },
          {
            text: view,
            to: {
              name: "app.sales.partner.detail.view",
            },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-partners-detail" */ "@/views/accounting/partners/PartnerDetailTabs"
        ),
  },
  {
    path: "/app/sales/partners/:partnerId",
    name: "app.sales.partner.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'sales',
      permission: ["admin-partner", "admin-partner-read"],
      breadCrumb(route) {
        const partnerId = route.params.partnerId;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Sales",
            to: { name: "app.sales" },
          },
          {
            text: "Partners",
            to: { name: "app.sales.partner" },
          },
          {
            text: partnerId,
            to: { name: "app.sales.partner.detail" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-partners-detail" */ "@/views/accounting/partners/PartnerDetailTabs"
      ),
  },
  {
    path: "/app/sales/partners",
    name: "app.sales.partner",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'sales',
      permission: ["admin-partner", "admin-partner-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Sales",
            to: { name: "app.sales" },
          },
          {
            text: "Partners",
            to: { name: "app.sales.partner" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-partners" */ "@/views/accounting/partners/PartnerView"
      ),
  },
];
