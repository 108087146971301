import prodNames from "@/util/prodNames";

export const Commission = [
  {
    path: "/app/accounting/commissions/:commissionId",
    name: "app.accounting.commission.detail",
    meta: {
      requiresAuth: true,
      department: 'accounting',
      prodName: prodNames.REVVIM,
      permission: ["admin-commission", "admin-commission-read"],
      breadCrumb(route) {
        const commissionId = route.params.commissionId;

        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Accounting",
            to: { name: "app.accounting" },
          },
          {
            text: "Commissions",
            to: { name: "app.accounting.commission" },
          },
          {
            text: commissionId,
            to: { name: "app.accounting.commission.detail" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-commissions" */ "@/views/accounting/commissions/CommissionDetail.vue"
      ),
  },
  {
    path: "/app/accounting/commissions",
    name: "app.accounting.commission",
    meta: {
      requiresAuth: true,
      department: 'accounting',
      prodName: prodNames.REVVIM,
      permission: ["admin-commission", "admin-commission-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Accounting",
            to: { name: "app.accounting" },
          },
          {
            text: "Commissions",
            to: { name: "app.accounting.commission" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-commissions" */ "@/views/accounting/commissions/CommissionView"
      ),
  },
];
