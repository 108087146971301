// AWS Amplify (Cognito session management and UI)

// @ts-ignore
import AmplifyVue from '@aws-amplify/ui-vue';
import { Amplify } from 'aws-amplify'
import { amplifyConfig } from "./config";

// @ts-ignore
Amplify.configure(amplifyConfig);

// This export will be used during initialization in App.vue
export { AmplifyVue }