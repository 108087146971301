import axios from "axios";
import {EventBus} from "@/event";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiPermissions = {
  async getPermissions() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/permissions/permissions.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getGroupPermissions() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/groups/group_permissions.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateGroupPermissions(groupname, permissions) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/groups/${groupname}/group_permissions.json`,
      permissions
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Group Permissions Updated"}); // this.$t('property.propertyDeleted')
    this.apiIsLoading = false;
    return result;
  },
  async getUserPermissions(username) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/permissions/users/${username}/user_permissions.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateUserAllowedPermissions(username, permissions) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/permissions/users/${username}/allowed/permissions.json`,
      permissions
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "User Allowed Permissions Updated"}); // this.$t('property.propertyDeleted')
    this.apiIsLoading = false;
    return result;
  },
  async updateUserDeniedPermissions(username, permissions) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/permissions/users/${username}/denied/permissions.json`,
      permissions
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "User Denied Permissions Updated"}); // this.$t('property.propertyDeleted')
    this.apiIsLoading = false;
    return result;
  }
};
