export const partnerDetailViews = [
  {
    id: 1,
    path: 'general',
    icon: "mdi-information",
    label: "General",
    permission: ["admin-partner", "admin-partner-read"],
  },
  {
    id: 2,
    path: 'deals',
    icon: "mdi-handshake-outline",
    label: "Deals",
    permission: ["admin-partner", "admin-partner-read"],
  },
  {
    id: 3,
    path: 'downline',
    icon: "mdi-file-tree-outline",
    label: "Downline",
    permission: ["admin-partner", "admin-partner-read"],
  },
  {
    id: 5,
    path: 'payments',
    icon: "mdi-hand-coin-outline",
    label: "Payments",
    permission: ["admin-partner-payment", "admin-partner-payment-read"],
  },
  {
    id: 6,
    path: 'commissions',
    icon: "mdi-cash-multiple",
    label: "Commissions",
    permission: ["admin-commission", "admin-commission-read"],
  },
  {
    id: 7,
    path: 'performance',
    img: "/assets/adai-logo-stacked.svg",
    label: "AdAi Performance",
    permission: ["admin-production", "admin-production-read"],
  },
  // {id: 4, label:'Checks', permission: 'admin-partner'},
]
