import axios from "axios";
import {EventBus} from "@/event";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiDashboards = {

  // Dashboards
  async getDashboards() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/dashboards/dashboards.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getDashboardById(dashboardId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/dashboards/${dashboardId}/dashboard.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateDashboard(dashboardId, payload) {
    this.apiIsLoading = true;
    let result;
    try {
      result = await axios.put(
        `${apiUrl}/auth/dashboards/${dashboardId}/dashboard.json`,
        payload
      );
      result = result.data;
      this.apiIsLoading = false;
      EventBus.$emit("snackbar", {text: "Success"});
      return result;
    } catch (e) {
      this.apiIsLoading = false;
      EventBus.$emit("snackbar", {color:"error", text: "Save Failed"});
    }
  },
  async updateDashboardsLocked(dashboardId, payload) {
    this.apiIsLoading = true;
    let result;
    try {
      result = await axios.put(
        `${apiUrl}/auth/dashboards/${dashboardId}/locked.json`,
        payload
      );
      result = result.data;
      this.apiIsLoading = false;
      EventBus.$emit("snackbar", {text: "Success"});
      return result;
    } catch (e) {
      this.apiIsLoading = false;
      EventBus.$emit("snackbar", {color:"error", text: "Save Failed"});
    }
  },
  async addDashboard(payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(`${apiUrl}/auth/dashboards/dashboard.json`, payload);
    EventBus.$emit("snackbar", {text: "Success"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },

  // Widgets
  async getDashboardWidgets() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/dashboards/widgets/widgets.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getDashboardWidgetById(widgetId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/dashboards/widgets/${widgetId}/widget.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateDashboardWidget(widgetId, payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/dashboards/widgets/${widgetId}/dashboard.json`,
      payload
    );
    EventBus.$emit("snackbar", {text: "Success"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async addDashboardWidget(payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(`${apiUrl}/auth/dashboards/widgets/widget.json`, payload);
    EventBus.$emit("snackbar", {text: "Success"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getDashboardWidgetData(widgetId, params) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/dashboards/widgets/${widgetId}/data/data.json`,
      params
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },

  // Permissions
  async getDashboardPermissionsByDashboardId(dashboardId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/dashboards/permissions/${dashboardId}/permissions.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateDashboardPermissions(dashboardId, payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/dashboards/permissions/${dashboardId}/permissions.json`,
      payload
    );
    EventBus.$emit("snackbar", {text: "Success"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  }

};
