import {apiAgency} from "./ApiAgency";
import {apiClients} from "./ApiClients";
import {apiCommissions} from "./ApiCommissions";
import {apiCron} from "./ApiCron";
import {apiDashboards} from "./ApiDashboards";
import {apiDeals} from './ApiDeals'
import {apiPipedrive} from "./pipedrive/index";
import {apiDomains} from "@/api/admin/ApiDomains";
import {apiDiscountGroups} from "./ApiDiscountGroups";
import {apiEmailHistory} from "./ApiEmailHistory";
import {apiGroups} from "./ApiGroups";
import {apiWorkOrders} from "./ApiWorkOrders";
import {apiOrganizations} from "./ApiOrganizations";
import {apiOnboards} from "@/api/admin/ApiOnboards";
import {apiPartners} from "./ApiPartners";
import {apiPayments} from "./ApiPayments";
import {apiPermissions} from "./ApiPermissions";
import {apiProperties} from "./ApiProperties";
import {apiProduction} from "./ApiProduction";
import {apiReports} from "./ApiReports";
import {apiUsers} from "./ApiUsers";
import {apiProducts} from "@/api/admin/ApiProducts";
import {apiProductPropertyGroups} from "@/api/admin/ApiProductPropertyGroups";
import {apiReact} from "@/api/admin/ApiReact";
import {apiAi} from "@/api/admin/ApiAI";
import axios from "axios";

export const apiUrl = import.meta.env.VITE_API_URL;

export const apiAdmin = {
  async resetCache() {
    this.apiIsLoading = true;
    await axios.get(`${apiUrl}/auth/admin/resetCache.json`);
    this.apiIsLoading = false;
    return true;
  },
  async getAdminDashboard() {
    this.apiIsLoading = true;
    const response = await axios.get(`${apiUrl}/auth/admin/dashboard.json`);
    this.apiIsLoading = false;
    return response.data;
  },
  ...apiClients,
  ...apiCommissions,
  ...apiCron,
  ...apiDashboards,
  ...apiDeals,
  ...apiDiscountGroups,
  ...apiDomains,
  ...apiEmailHistory,
  ...apiGroups,
  ...apiWorkOrders,
  ...apiOnboards,
  ...apiOrganizations,
  ...apiPartners,
  ...apiPayments,
  ...apiPermissions,
  ...apiPipedrive,
  ...apiProperties,
  ...apiProducts,
  ...apiUsers,
  ...apiProduction,
  ...apiProductPropertyGroups,
  ...apiReports,
  ...apiReact,
  ...apiAi,
  ...apiAgency
};
