import axios from "axios";
import {EventBus} from "@/event";

export const apiUrl = import.meta.env.VITE_API_URL;

export const apiNonCompeteDomains = {
  async getPropertyNonCompeteDomains(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/${propertyId}/noncompete/domains.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updatePropertyNonCompeteDomains(propertyId, payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/admin/properties/${propertyId}/noncompete/domain.json`,
      payload
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Property Domain Updated"});
    this.apiIsLoading = false;
    return result;
  },
  async deletePropertyNonCompeteDomain(propertyId, payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/admin/properties/${propertyId}/noncompete/delete/domain.json`,
      payload
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Property Domain Removed"});
    this.apiIsLoading = false;
    return result;
  },
}
