import prodNames from "@/util/prodNames";

export const WorkOrder = [
  {
    path: "/app/accounting/workOrders/:workOrderId",
    name: "app.accounting.workOrder.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'accounting',
      permission: ["admin-work-order", "admin-work-order-read"],
      breadCrumb(route) {
        const workOrderId = route.params.workOrderId;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Accounting",
            to: { name: "app.accounting" },
          },
          {
            text: "Work Orders",
            to: { name: "app.accounting.workOrder" },
          },
          {
            text: workOrderId,
            to: { name: "app.accounting.workOrder.detail" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-workOrders" */ "@/views/accounting/workOrders/WorkOrdersItemsTable"
      ),
  },
  {
    path: "/app/accounting/workOrders",
    name: "app.accounting.workOrder",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'accounting',
      permission: ["admin-work-order", "admin-work-order-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Accounting",
            to: { name: "app.accounting" },
          },
          {
            text: "Work Orders",
            to: { name: "app.accounting.workOrder" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-workOrders" */ "@/views/accounting/workOrders/WorkOrdersView"
      ),
  },
];
