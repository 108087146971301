import axios from "axios";
import {EventBus} from "@/event";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiPayments = {
  async getPayments() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/payments/payments.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPaymentById(paymentId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/payments/${paymentId}/payment.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPaymentsForPartner(partnerId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/payments/partner/${partnerId}/payments.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async createPayment(payment) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/payments/payment.json`,
      payment
    );
    EventBus.$emit("snackbar", {text: "Payment Created"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updatePayment(paymentId, payment) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/payments/${paymentId}/payment.json`,
      payment
    );
    EventBus.$emit("snackbar", {text: "Payment Updated"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async deletePaymentById(paymentId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.delete(
      `${apiUrl}/auth/payments/${paymentId}/payment.json`
    );
    EventBus.$emit("snackbar", {text: "Payment Deleted"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  }
};
