import prodNames from "@/util/prodNames";

export const Accounting = [
  {
    path: "/app/accounting/partners/:partnerId/view/:view",
    name: "app.accounting.partner.detail.view",
    meta: {
      requiresAuth: true,
      department: 'accounting',
      prodName: prodNames.REVVIM,
      permission: ["admin-partner", "admin-partner-read"],
      breadCrumb(route) {
        const partnerId = route?.params?.partnerId;
        const view = route?.params?.view;
        return [
          {
            text: "Home",
            to: {
              name: "app-home",
            },
          },
          {
            text: "Customer Service",
            to: {
              name: "app.accounting",
            },
          },
          {
            text: "Partners",
            to: {
              name: "app.accounting.partner",
            },
          },
          {
            text: partnerId,
            to: {
              name: "app.accounting.partner.detail",
            }
          },
          {
            text: view,
            to: {
              name: "app.accounting.partner.detail.view",
            },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-partners-detail" */ "@/views/accounting/partners/PartnerDetailTabs"
        ),
  },
  {
    path: "/app/accounting/partners/:partnerId",
    name: "app.accounting.partner.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'accounting',
      permission: ["admin-partner", "admin-partner-read"],
      breadCrumb(route) {
        const partnerId = route.params.partnerId;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Accounting",
            to: { name: "app.accounting" },
          },
          {
            text: "Partners",
            to: { name: "app.accounting.partner" },
          },
          {
            text: partnerId,
            to: { name: "app.accounting.partner.detail" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-partners-detail" */ "@/views/accounting/partners/PartnerDetailTabs"
        ),
  },
  {
    path: "/app/accounting/partners",
    name: "app.accounting.partner",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'accounting',
      permission: ["admin-partner", "admin-partner-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Accounting",
            to: { name: "app.accounting" },
          },
          {
            text: "Partners",
            to: { name: "app.accounting.partner" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-partners" */ "@/views/accounting/partners/PartnerView"
        ),
  },
  {
    path: "/app/accounting",
    name: "app.accounting",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'accounting',
      permission: [
        "admin-accounting-client",
        "admin-accounting-client-read"
      ],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Accounting",
            to: { name: "app.accounting" },
          }
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-clients" */ "@/views/accounting/AccountingDashboard.vue"
        ),
  },
];
