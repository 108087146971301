import prodNames from "@/util/prodNames";

export const Onboards = [
  // {
  //   path: "/app/admin/onboards/add",
  //   name: "app.admin.agent.onboard.add",
  //   meta: {
  //     requiresAuth: true,
  //     prodName: prodNames.REVVIM,
  //     permission: ["admin-property"],
  //     breadCrumb(route) {
  //       return [
  //         {
  //           text: "Home",
  //           to: {
  //             name: "app-home",
  //           },
  //         },
  //         {
  //           text: "Admin",
  //           to: {
  //             name: "app.admin",
  //           },
  //         },
  //         {
  //           text: "Onboards",
  //           to: { name: "app.admin.onboard" },
  //         },
  //         {
  //           text: 'Add'
  //         },
  //       ];
  //     },
  //   },
  //   props: true,
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "Admin-agents" */ "@/views/admin/onboard/OnboardPropertyWizard.vue"
  //       ),
  // },
  {
    path: "/app/admin/onboards/:onboardId",
    name: "app.admin.onboard.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-onboards", "admin-onboards-read"],
      breadCrumb(route) {
        const onboardId = route.params.onboardId;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Admin",
            to: { name: "app.admin" },
          },
          {
            text: "Onboards",
            to: { name: "app.admin.onboard" },
          },
          {
            text: onboardId,
            to: { name: "app.admin.onboard.detail" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-onboards-detail" */ "@/views/admin/onboard/OnboardPropertyWizard.vue"
        ),
  },
  {
    path: "/app/admin/onboards",
    name: "app.admin.onboard",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-onboards", "admin-widgets", "admin-onboards-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: {name: "app-home"},
          },
          {
            text: "Admin",
            to: {name: "app.admin"},
          },
          {
            text: "Onboards",
            to: {name: "app.admin.onboard"},
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-onboardsOnboards" */ "@/views/admin/onboard/OnboardsView.vue"
        )
  }
];
