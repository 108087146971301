export const propertyDetailViews = [
  {
    path: 'reports',
    icon: "mdi-chart-bar",
    label: "Reports",
    product: ["AdAi"],
    permission: ["admin-property", "admin-property-read"]
  },
  {
    path: 'production',
    icon: "mdi-cash-register",
    label: "Production",
    permission: ["admin-property", "admin-property-read"]
  },
  {
    path: 'scheduledactions',
    icon: "mdi-clock",
    label: "Scheduled Actions",
    permission: ["admin-property", "admin-property-read"]
  },
  {
    path: 'users',
    icon: "mdi-account-circle",
    label: "Users",
    permission: ["admin-property", "admin-property-read"]
  },
  {
    path: 'campaign',
    icon: "mdi-target-account",
    label: "Campaign Targeting",
    permission: ["admin-property"]
  },
  // {
  //   path: 'lbc',
  //   icon: "mdi-package-down",
  //   label: "Low Bid Campaign",
  //   permission: ["admin-property"]
  // },
  {
    path: 'domaintargeting',
    icon: "mdi-domain",
    label: "Domain Targeting",
    permission: ["admin-property", "admin-property-read"]
  },
  {
    path: 'geo',
    icon: "mdi-flag",
    label: "Geo Targeting",
    permission: ["admin-property", "admin-property-read"]
  },
  {
    path: 'keywordtargeting',
    icon: "mdi-bullseye-arrow",
    label: "Term Targeting",
    product: ["AdAi","FraudAi"],
    permission: ["admin-property-read"]
  },
  {
    path: 'changelog',
    icon: "mdi-chart-bar",
    label: "Change Log",
    permission: ["admin-property-read"]
  },
  {
    path: 'settings',
    icon: "mdi-cog",
    label: "Settings",
    permission: ["admin-property"]
  },
  // {
  //   path: 8,
  //   label: "Events",
  //   permission: ["admin-property", "admin-property-read"]
  // },
  // {
  //   path: 9,
  //   label: "Dashboard",
  //   permission: ["admin-property-read"]
  // },
  // {
  //   path: 10,
  //   label: "Keywords",
  //   permission: ["admin-property-read"]
  // },

]
