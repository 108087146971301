export const clientDetailViews = [
  {
    id: 1,
    icon: "mdi-information",
    path:'general',
    label: "General",
    permission: ["admin-client", "admin-client-read"],
  },
  {
    id: 8,
    icon: "mdi-shield-account",
    path:'agents',
    label: "Agents",
    permission: ["admin-property", "admin-property-read"],
  },
  {
    id: 2,
    icon: "mdi-handshake-outline",
    path:'deals',
    label: "Deals",
    permission: ["admin-client", "admin-client-read"],
  },
  {
    id: 7,
    icon: "mdi-invoice",
    path:'workorders',
    label: "Work Orders",
    permission: ["admin-work-order-read", "admin-work-order"],
  },
  {
    id: 4,
    icon: "mdi-account-cash",
    path:'payments',
    label: "Payments",
    permission: ["admin-client-payment", "admin-client-payment-read"],
  },
  {
    id: 5,
    icon: "mdi-shield-account",
    img: "/assets/adai-logo-stacked.svg",
    path:'adai',
    label: "Ad Ai",
    permission: ["admin-production", "admin-production-read"],
    products: [1],
  },
  {
    id: 6,
    icon: "mdi-shield-account",
    img: "/assets/react-logo-stacked.svg",
    path:'react',
    label: "React",
    permission: ["admin-production", "admin-production-read"],
    products: [3],
  },
  {
    id: 7,
    icon: "mdi-shield-account",
    img: "/assets/fraudai-logo-stacked.svg",
    path:'fraudai',
    label: "FraudAi",
    permission: ["admin-production", "admin-production-read"],
    products: [2],
  },
]
