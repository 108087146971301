import { defineStore } from 'pinia'
import sortArrayObjects from "@/util/sortArrayObjects";
import { Locale } from 'vue-i18n';
import { RouteLocation } from 'vue-router';

type propertyId = Number;
type property = {
  propertyId: propertyId,
  name: string
}
type user = {
  signedIn: boolean,
  properties: property[],
  permissions: any,
}
type permission = string | any[];
type serverState = {
  permissions: permission[],
  system: any
}
type appState = {
  userAuthenticated: user,
  user: user,
  permissions: permission[],
  system: any
}

const getPropertyIndex = (propertyId:propertyId, properties:property[]) => {
  return properties.findIndex(property => property.propertyId === propertyId);
};

export const useAppStore = defineStore('app', {
  state: () => ({
    user: {
      signedIn: false,
      properties: [],
      permissions: null
    } as user,
    impersonatedUser: undefined as user | undefined,
    serverState: undefined as serverState | undefined,
    cognito: {
      attributes: {}
    },
    settings: {
      hideMenu: false
    },
    tablePrefs: {
      //impact
      //impactKw
      //recentChanges
      //recentChangesKw
    },
    datePrefs: {},
    prevRoute: null as RouteLocation | null,
    preLoginRoute: null as RouteLocation | null,
    locale: "en",
    lastSelectedProduct: null as string | null,
    userInviteToken: null,
    userAuthenticated: null as user | null
  }),
  actions: {
    setUser(user:user) {
      if (user.properties) {
        user.properties = sortArrayObjects(user.properties, "name");
      }
      this.user = Object.assign({}, this.user, user);
    },
    setSettings(settings:any) {
      this.settings = Object.assign({}, this.settings, settings);
    },
    setTablePrefs(prefs:any) {
      //@ts-ignore
      this.tablePrefs[prefs.name] = prefs.settings;
    },
    setDatePrefs(prefs:any) {
      //@ts-ignore
      this.datePrefs[prefs.name] = prefs.dates;
    },
    setPropertyDetails(updatedProp:property) {
      const index = getPropertyIndex(
        updatedProp.propertyId,
        this.user.properties
      );
      if (index > -1) {
        const existingProperty = this.user.properties[index];
        this.user.properties[index] = Object.assign({}, existingProperty, updatedProp)
      }
    },
    setPrevRoute(route:RouteLocation) {
      this.prevRoute = route;
    },
    setPreLoginRoute(route:RouteLocation) {
      this.preLoginRoute = route;
    },
    setCognitoAttributes(newAttributes:object) {
      let attributes = Object.assign(
        {},
        this.cognito.attributes,
        newAttributes
      );
      this.cognito = { attributes };
    },
    setSignedIn(signedIn:boolean) {
      this.user.signedIn = signedIn;
    },
    discardSession() {
      this.$reset();
    },
    setLocale(locale:Locale) {
      this.locale = locale;
    },
    addProperty(property:property) {
      this.user.properties.push(property);
      this.user.properties = sortArrayObjects(this.user.properties, "name");
    },
    removeProperty(propertyId:propertyId) {
      const propertyIndex = getPropertyIndex(propertyId, this.user.properties);
      this.user.properties.splice(propertyIndex, 1);
      if (this.user.properties.length) {
        this.user.properties = sortArrayObjects(this.user.properties, "name");
      }
    },
    setLastSelectedProduct(productName:string) {
      this.lastSelectedProduct = productName;
    },
    setImpersonation(user:user) {
      this.impersonatedUser = user;
    },
    clearImpersonation() {
      this.impersonatedUser = undefined;
    },
    setServerAppState(appState:appState) {
      this.serverState = {
        permissions: appState.permissions,
        system: appState.system
      };

      // merge server validated user information over user state
      this.user.permissions = appState.user.permissions;
      // replace the userAuthenticated object with the one from the server
      this.userAuthenticated = appState.userAuthenticated;
    }
    /*
    setViewSubscriptions(propertyId, subscribedViews){
      const propertyIndex = getPropertyIndex(propertyId, this.user.properties);
      let property = this.users.properties[propertyIndex];
      property.views = subscribedViews;
      this.users.properties[propertyIndex] = property;
    }*/
    //setCognito(cognito){
    //  this.cognito = cognito;
    //},
  },
  getters: {
    signedIn: state => !!state.user.signedIn,
    hasPermission: state => (permission: permission) => {
      if (typeof permission === "string") {
        return (
          state.user.permissions &&
          (state.user.permissions.includes(permission) ||
            state.user.permissions.includes("host"))
        );
      } else {
        let has = false;
        permission.map(p => {
          if (
            state.user.permissions &&
            (state.user.permissions.includes(p) ||
              state.user.permissions.includes("host"))
          ) {
            has = true;
          }
        });
        return has;
      }
    },
    hasAuthUserPermission: state => (permission:permission) => {
      if (typeof permission === "string") {
        return (
          state?.userAuthenticated?.permissions &&
          (state.userAuthenticated.permissions.includes(permission) ||
            state.userAuthenticated.permissions.includes("host"))
        );
      } else {
        let has = false;
        permission.map(p => {
          if (
            state?.userAuthenticated?.permissions &&
            (state.userAuthenticated.permissions.includes(p) ||
              state.userAuthenticated.permissions.includes("host"))
          ) {
            has = true;
          }
        });
        return has;
      }
    }
  },
  persist: true,
})
