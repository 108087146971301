import axios from "axios";
import {EventBus} from "@/event";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiClients = {
  async getClients() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/clients/clients.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getClientById(clientId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/clients/${clientId}/client.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getClientPaymentById(paymentId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/clients/payments/${paymentId}/payment.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async createClient(client) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(`${apiUrl}/auth/clients/client.json`, client);
    EventBus.$emit("snackbar", {text: "Client Created"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateClient(clientId, client) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/clients/${clientId}/client.json`,
      client
    );
    EventBus.$emit("snackbar", {text: "Client Updated"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async deleteClientById(clientId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.delete(
      `${apiUrl}/auth/clients/${clientId}/client.json`
    );
    EventBus.$emit("snackbar", {text: "Client Deleted"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getDealsForClient(clientId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/clients/${clientId}/deals.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async assignClientToDeal(clientId, dealId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/clients/${clientId}/deals/${dealId}/client.json`
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Deal Assigned"});
    this.apiIsLoading = false;
    return result;
  },
  async removeDealForClient(clientId, dealId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.delete(
      `${apiUrl}/auth/clients/${clientId}/deals/${dealId}/client.json`
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Deal Un-Assigned"});
    this.apiIsLoading = false;
    return result;

  },
  async getDealsUnassignedToClient() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/clients/unassigned/deals.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getProductionForClient(clientId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/clients/${clientId}/production.json`
    );
    this.apiIsLoading = false;
    result = result.data;
    return result;
  },
  async getClientPeriodProperties(clientId, yearMonth) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/clients/${clientId}/period/${yearMonth}/properties.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateClientProductionForPeriod(clientId, production) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/clients/${clientId}/production/production.json`,
      production
    );
    EventBus.$emit("snackbar", {text: "Client Production Updated"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateClientPeriodPropertyBillable(
    clientId,
    yearMonth,
    propertyBillable
  ) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/clients/${clientId}/period/${yearMonth}/propertyBillable.json`,
      propertyBillable
    );
    EventBus.$emit("snackbar", {text: "Client Production Updated"});
    this.apiIsLoading = false;
    result = result.data;
    return result;
  },
  async recalculateClientPeriod(clientId, yearMonth) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/clients/${clientId}/period/${yearMonth}/recalculate.json`
    );
    EventBus.$emit("snackbar", {text: "Client Production Updated"});
    this.apiIsLoading = false;
    result = result.data;
    return result;
  },
  async createClientPayment(payment) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(`${apiUrl}/auth/clients/payment.json`, payment);
    EventBus.$emit("snackbar", {text: "Payment Created"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async deleteClientPaymentById(clientId, paymentId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.delete(
      `${apiUrl}/auth/clients/${clientId}/payments/${paymentId}/payment.json`
    );
    EventBus.$emit("snackbar", {text: "Payment Deleted"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getClientsPayments() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/clients/payments.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getClientsPaymentsByClientId(clientId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/clients/${clientId}/payments.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateClientPaymentNotes(clientId, item) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/clients/${clientId}/payment/notes.json`,
      item
    );
    EventBus.$emit("snackbar", {text: "Notes Updated"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getProductsAssignedToClient(clientId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/clients/${clientId}/products.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getClientPartnersWithCommissionOptOut(clientId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/clients/${clientId}/partner_commission_optout.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
};
