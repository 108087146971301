import {apiDeals} from "./ApiDeals.js";
import {apiOrganizations} from "./ApiOrganizations.js";
import {apiPipeDrivePipelines} from "./ApiPipelines.js";
import {apiUsers} from "./ApiUsers.js";
import {apiLeads} from "./ApiLeads.js";
import {apiGoals} from "./ApiGoals.js";

export const apiPipedrive = {
  ...apiDeals,
  ...apiGoals,
  ...apiLeads,
  ...apiOrganizations,
  ...apiPipeDrivePipelines,
  ...apiUsers
}
