import axios from "axios";
const apiUrl = import.meta.env.VITE_API_URL;

export const apiGroups = {
  async getGroups() {
    this.apiIsLoading = true;
    let result;
      result = await axios.get(`${apiUrl}/auth/groups/groups.json`);
      result = result.data.Groups;
      this.apiIsLoading = false;
    return result;
  },
  async getGroupsForUsername(username) {
    this.apiIsLoading = true;
    let result;
      result = await axios.get(`${apiUrl}/auth/users/${username}/groups.json`);
      result = result.data.Groups;
      this.apiIsLoading = false;
    return result;
  },
  async addUserToGroup(username, groupname) {
    this.apiIsLoading = true;
    let result;
      result = await axios.post(
        `${apiUrl}/auth/users/${username}/groups/${groupname}/user.json`
      );
      result = result.data.Groups;
      this.apiIsLoading = false;
    return result;
  },
  async removeUserFromGroup(username, groupname) {
    this.apiIsLoading = true;
    let result;
      result = await axios.delete(
        `${apiUrl}/auth/users/${username}/groups/${groupname}/user.json`
      );
      result = result.data.Groups;
      this.apiIsLoading = false;
    return result;
  },
  async getUsersInGroup(groupname) {
    this.apiIsLoading = true;
    let result;
      result = await axios.get(`${apiUrl}/auth/groups/${groupname}/users.json`);
      result = result.data;
      this.apiIsLoading = false;
    return result;
  }
};
