import prodNames from "@/util/prodNames";

export const Agencies = [
  {
    path: "/app/sales/agencies/:clientId",
    name: "app.sales.agency.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'sales',
      permission: ["admin-agency", "admin-agency-read"],
      breadCrumb(route) {
        const clientId = route.params.clientId;
        return [
          {
            text: "Home",
            to: {
              name: "app-home",
            },
          },
          {
            text: "Sales",
            to: {
              name: "app.sales",
            },
          },
          {
            text: "Agencies",
            to: {
              name: "app.sales.agency",
            },
          },
          {
            text: clientId,
            to: {
              name: "app.sales.agency.detail",
            },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-agents" */ "@/views/admin/property/PropertyDetailTabs.vue"
        ),
  },
  {
    path: "/app/sales/agencies",
    name: "app.sales.agency",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'sales',
      permission: ["admin-property", "admin-property-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: {
              name: "app-home",
            },
          },
          {
            text: "Sales",
            to: {
              name: "app.sales",
            },
          },
          {
            text: "Agencies",
            to: {
              name: "app.sales.agency",
            },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-agents" */ "@/views/sales/agencies/AgenciesView.vue"
        ),
  },
];
