import axios from "axios";
import {EventBus} from "@/event";
export const apiUrl = import.meta.env.VITE_API_URL;
export const apiDeal = {
  async getPropertiesForDealAdmin(dealId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/deal/${dealId}/properties.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPropertiesUnassignedToDeal() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/deal/unassigned/properties.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async addPropertyToDeal(propertyId, dealId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/admin/properties/deal/assign/property.json`,
      {propertyId, dealId}
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Property Added"}); // this.$t('property.propertyDeleted')
    this.apiIsLoading = false;
    return result;
  },

}
