let helpContent={
    adai: {}
};

helpContent.adai.impactChartTitle='Ayuda del gráfico de impacto de AdAi';
helpContent.adai.impactChartContent=`
<h3 class="title mb-2">¿Qué me muestra este informe?</h3>
<p> Este informe le muestra cómo AdAi pudo desviar el tráfico de los clics en sus anuncios a sus clics orgánicos sin un impacto negativo en su tasa de clics combinada (BCTR). Tenga en cuenta que los datos siempre tienen un retraso de 2 a 3 días debido a la limitación de la API de la Consola de búsqueda de Google. </p>

<h3 class="title mb-2"> Definiciones de informes </h3>

<h4> CTR combinado </h4>
<p> La tasa de clics combinada muestra el porcentaje de búsquedas en este conjunto de <a rel="noopener noreferrer" target="_ blank" href="https://support.google.com/google-ads/answer/2472708"> términos de búsqueda </a> que dieron como resultado un clic en su anuncio pago o en la lista orgánica. Puede colocar el cursor sobre el CTR combinado para verlo resaltado o hacer clic en él para ocultarlo. </p>

<h4> Costo del anuncio </h4>
<p> El costo del anuncio es el costo total de los anuncios en Google para el período especificado para los términos de búsqueda que fueron no disputades durante ese período.
</p>

<h4> Clics orgánicos </h4>
<p> Los clics orgánicos que se muestran aquí son el total de clics gratuitos para este conjunto de términos de búsqueda tomados de su Google Search Console. </p>

<h4> Clics en anuncios </h4>
<p> Los clics en anuncios que se muestran aquí son el total de clics en anuncios para este conjunto de términos de búsqueda tomados de su cuenta de Google Ads. </p>
<p> Nota: debido a que los clics orgánicos y los clics en anuncios se muestran en una columna apilada, a menudo resulta visualmente útil ocultar uno de ellos para verlos en términos absolutos y no relativos haciendo clic en ellos en la clave proporcionada. </p>`;

helpContent.adai.reclaimedChartTitle='Ayuda de gráficos reclamados por AdAi';
helpContent.adai.reclaimedChartContent=`
<h3 class="title mb-2"> ¿Qué me muestra este informe? </h3>
<p> Este informe le muestra por día el dinero que se gastó y ahorró en el conjunto específico de <a rel="noopener noreferrer" target="_ blank" href="https://support.google.com/google-ads/answer/2472708"> términos de búsqueda </a> de AdAi en ese día específico. También muestra la tarifa para el rango de fechas seleccionado.
</p>
<p> Para vincularlo a una factura esperada para un mes, seleccione el intervalo de fechas para ese mes. Nuestra facturación se basa en una estructura por niveles, por lo que es posible que los intervalos de fechas más amplios no reflejen la factura correctamente porque facturamos mensualmente.
</p>
<p> Nota: debido a que el gasto real y el gasto reclamado se muestran en una columna apilada, a menudo resulta visualmente útil ocultar uno de ellos para verlos en términos absolutos y no relativos haciendo clic en ellos en la clave proporcionada.
</p>

<h3 class="title mb-2"> Definiciones de informes </h3>

<h4> Inversión real </h4>
<p> La inversión real muestra la cantidad en dólares que se gastó en este conjunto particular de términos de búsqueda para el día específico en cuestión.
</p>
<h4> Inversión reclamada </h4>
<p> La inversión recuperada muestra la cantidad en dólares que AdAi pudo ahorrar al gastarse en este conjunto particular de términos de búsqueda para el día específico en cuestión. <a rel="noopener noreferrer" target="_blank" href="https://revvim.com/calculating-reclaimed-ad-budget"> Vea cómo Revvim calcula el gasto recuperado </a>.
</p>
`;

helpContent.adai.conquestedTableTitle='Ayuda de términos de búsqueda solicitados de AdAi';
helpContent.adai.conquestedTableContent=`
<h3 class="title mb-2"> ¿Qué me muestra este informe? </h3>
<p>Este informe muestra los <a rel="noopener noreferrer" target="_blank" href="https://support.google.com/google-ads/answer/2472708"> términos de búsqueda </a> para los que tiene un Listado # 1 de SEO que se descubrió que fue conquistado por un competidor durante la última hora.
</p>
<p> Si no se muestran términos de búsqueda aquí, ¡buen trabajo! Significa que está protegiendo su marca de la competencia. Si hay términos de búsqueda enumerados aquí, representan una oportunidad para reinvertir parte del gasto recuperado. Por ejemplo, tal vez pueda crear un anuncio nuevo para proteger los términos de búsqueda o tal vez aumente el presupuesto de un anuncio existente. </p>

<h3 class="title mb-2"> Definiciones de informes </h3>

<h4> Términos de búsqueda disputados </h4>
<p> Un término de búsqueda disputado es un término de búsqueda en el que tiene una lista orgánica número uno que está siendo rechazada por anuncios de la competencia sin ningún anuncio propio durante una parte del día. </p>

<h4> Impresiones mensuales totales </h4>
<p> Esto muestra el número de impresiones según Google Search Console durante los últimos 28 días para este término de búsqueda. </p>
`;

helpContent.adai.activityLogTitle='Ayuda del registro de actividad de AdAi';
helpContent.adai.activityLogContent=`
<h3 class="title mb-2"> Definiciones de informes </h3>

<h4> Término de búsqueda </h4>
<p> Estos son los <a rel="noopener noreferrer" target="_blank" href="https://support.google.com/google-ads/answer/2472708"> términos de búsqueda </a> para los que tiene una lista de SEO n. ° 1 donde AdAi pudo suprimir su anuncio porque no había ningún competidor presente. Esta lista no representa todos los términos de búsqueda que AdAi puede estar monitoreando, sino solo aquellos para los que AdAi pudo actuar. </p>

<h4> Acción </h4>
<p> Hay tres acciones que AdAi puede realizar: </p>
<ol>
<li> Agregado a la lista de palabras clave negativas: cuando se agrega un término de búsqueda a la lista de palabras clave negativas, su anuncio se suprime (no se desactiva ni se inhabilita) </li>
<li> Quitado de la lista de palabras clave negativas: el término de búsqueda se quita de la lista de palabras clave negativas para que se muestre su anuncio. </li>
<li> Rebasamiento de costos iniciado / finalizado: ocasionalmente permitiremos que se muestre un término de búsqueda incluso cuando sea no disputado. Hacemos esto para asegurarnos de tener los datos de CTR y CPC más recientes y precisos para los términos de búsqueda, valores que se utilizan en nuestros cálculos de <a rel="noopener noreferrer" target="_blank" href="https://revvim.com/calculating-reclaimed-ad-budget">inversión publicitaria recuperada</a>. </li>
</ol>
<h4> Fecha </h4>
<p> Esto muestra la fecha y hora en que se tomó la acción. </p>
`;

export default helpContent;
