import prodNames from "@/util/prodNames";

export const Domain = [
  {
    path: "/app/admin/domains/:domainId",
    name: "app.admin.domain.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-property", "admin-property-read"],
      breadCrumb(route) {
        const domainId = route.params.domainId;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Admin",
            to: { name: "app.admin" },
          },
          {
            text: "Domains",
            to: { name: "app.admin.domain" },
          },
          {
            text: domainId,
            to: { name: "app.admin.domain.detail" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-agents" */ "@/views/admin/domains/DomainTabs.vue"
      ),
  },
  {
    path: "/app/admin/domains",
    name: "app.admin.domain",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-property", "admin-property-read"],
      breadCrumb(route) {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Admin",
            to: { name: "app.admin" },
          },
          {
            text: "Domains",
            to: { name: "app.admin.domain" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-agents" */ "@/views/admin/domains/DomainsTable"
      ),
  },
];
