/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
import { registerPlugins } from '@/plugins'
import { useAppStore } from '@/stores/app';
import formatter from '@/formatter.js'

// Components
import App from './App.vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

// Composables
import { createApp } from 'vue'

// Styles
import "@/styles/main.scss";

const app = createApp(App)

registerPlugins(app)

// Set global store
app.config.globalProperties.$store = useAppStore();
app.config.globalProperties.$formatter = formatter;

app.component('VueDatePicker', VueDatePicker);

app.mount('#app')
