import axios from "axios";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiPipeDrivePipelines = {
  async getPipelines() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/pipedrive/pipelines/pipelines.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPipeDrivePipelineById(pipelineId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/pipedrive/pipelines/${pipelineId}/pipeline.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  }
}
