import prodNames from "@/util/prodNames";

export const User = [
  {
    path: "/app/admin/user",
    name: "app.admin.user",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: "admin-user-read",
      breadCrumb(route) {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Admin",
            to: { name: "app.admin" },
          },
          {
            text: "Users",
            to: { name: "app.admin.user" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-user" */ "@/views/admin/userAdmin.vue"
      ),
  },
  {
    path: "/app/admin/user/:userId?",
    name: "app.admin.user.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: "admin-user-read",
      breadCrumb(route) {
        const userId = route.params.userId;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Admin",
            to: { name: "app.admin" },
          },
          {
            text: "Users",
            to: { name: "app.admin.user" },
          },
          {
            text: userId,
            to: { name: "app.admin.user.detail" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-user" */ "@/views/admin//user/UserDetailTabs.vue"
      ),
  },
];
