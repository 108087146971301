import axios from "axios";
import {EventBus} from "@/event";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiOnboards = {

  // Onboards
  async getOnboards() {
    this.apiIsLoading = true;
    let result
    try {
      result = await axios.get(`${apiUrl}/auth/admin/onboards/onboards.json`)
      result = result.data;
      this.apiIsLoading = false;
      return result;
    } catch (e) {
      this.apiIsLoading = false;
      return Promise.reject(e);
    }
  },
  async getOnboardById(onboardId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/onboards/${onboardId}/onboard.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateOnboard(onboardId, payload) {
    this.apiIsLoading = true;
    let result;
    try {
      result = await axios.put(
        `${apiUrl}/auth/admin/onboards/${onboardId}/onboard.json`,
        payload
      );
      result = result.data;
      this.apiIsLoading = false;
      EventBus.$emit("snackbar", {color:"primary", text: `Updated. Active step now ${payload.Step}`});
      return result;
    } catch (e) {
      this.apiIsLoading = false;
      EventBus.$emit("snackbar", {color:"error", text: "Save Failed"});
    }
  },
  async addOnboard(payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(`${apiUrl}/auth/admin/onboards/onboard.json`, payload);
    EventBus.$emit("snackbar", {text: "Success"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async upsertOnboardFromLeadList(payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(`${apiUrl}/auth/admin/onboards/leads/upsert_onboard.json`, payload);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getOnboardPropetiesdByOnbboardId(onboardId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/onboards/${onboardId}/properties/property.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getOnboardPropertyScriptValidation(onboardId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/onboards/${onboardId}/properties/property.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateOnboardLearningMode(payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(`${apiUrl}/auth/admin/onboards/learning/property.json`, payload);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateOnboardPropertyOrg(payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(`${apiUrl}/auth/admin/onboards/org/onboard.json`, payload);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },

};
