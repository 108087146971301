import axios from "axios";
import {EventBus} from "@/event";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiAi = {
  async getAiResponse(payload) {
    this.apiIsLoading = true;
    let result;

    result = await axios.post(
      `${apiUrl}/auth/admin/ai/completion.json`,
      payload
    );
    EventBus.$emit("snackbar", {text: "Sent"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  }
};
