import prodNames from "@/util/prodNames";

export const Widget = [
  {
    path: "/app/admin/dashboards/widgets/:widgetId",
    name: "app.admin.dashboard.widget.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-dashboard", "admin-widgets"],
      breadCrumb(route) {
        const widgetId = route.params.widgetId;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Admin",
            to: { name: "app.admin" },
          },
          {
            text: "Dashboards",
            to: { name: "app.admin.dashboard" },
          },
          {
            text: "Widgets",
            to: { name: "app.admin.dashboard.widget" },
          },
          {
            text: widgetId,
            to: { name: "app.admin.dashboard.widget.detail" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-widgets-detail" */ "@/views/admin/dashboard/widget/WidgetDetail.vue"
        ),
  },
  {
    path: "/app/admin/dashboards/widgets",
    name: "app.admin.dashboard.widget",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-dashboard", "admin-widgets"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: {name: "app-home"},
          },
          {
            text: "Admin",
            to: {name: "app.admin"},
          },
          {
            text: "Dashboards",
            to: { name: "app.admin.dashboard" },
          },
          {
            text: "Widgets",
            to: {name: "app.admin.dashboard.widget"},
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-widgetsWidgets" */ "@/views/admin/dashboard/widget/WidgetsView.vue"
        )
  }
];
