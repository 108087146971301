import axios from "axios";
import {EventBus} from "@/event";
export const apiUrl = import.meta.env.VITE_API_URL;
export const apiFraudAi = {
  async getFraudAiPropertiesEnabledForClient(clientId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/fraudai/client/${clientId}/enabled/properties.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateFraudAiEnabledForProperty(assignment) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/admin/properties/fraudai/enabled/action.json`,
      assignment
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Property Updated"});
    this.apiIsLoading = false;
    return result;
  },
  async getPropertyFraudAiSearchTerms(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/fraudai/property/${propertyId}/searchterms.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateFraudAiSearchTermEnabledForProperty(propertyId, assignment) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/admin/properties/fraudai/property/${propertyId}/searchterm/enabled/searchterm.json`,
      assignment
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Search Term Updated"});
    this.apiIsLoading = false;
    return result;
  },
  async addFraudAiSearchTermForProperty(propertyId, assignment) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/properties/fraudai/property/${propertyId}/searchterm/searchterm.json`,
      assignment
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Search Term Added"});
    this.apiIsLoading = false;
    return result;
  },
  async updateFraudAiSearchTermForProperty(propertyId, assignment) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/admin/properties/fraudai/property/${propertyId}/searchterm/searchterm.json`,
      assignment
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Search Terms Updated"});
    this.apiIsLoading = false;
    return result;
  },
  async addFraudAiMatchingRuleForProperty(propertyId, assignment) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/properties/fraudai/property/${propertyId}/matchingrule/matchingrule.json`,
      assignment
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Matching Rule Added"});
    this.apiIsLoading = false;
    return result;
  },
  async updateFraudAiMatchingRuleForProperty(propertyId, assignment) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/admin/properties/fraudai/property/${propertyId}/matchingrule/matchingrule.json`,
      assignment
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Matching Rule Updated"});
    this.apiIsLoading = false;
    return result;
  },
  async getPropertyFraudAiMatchingRules(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/fraudai/property/${propertyId}/matchingrules.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async addFraudAiBulkMatchingRuleForProperty(propertyId, assignment) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/properties/fraudai/property/${propertyId}/matchingrule/bulk/matchingrule.json`,
      assignment,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Matching Rule(s)"});
    this.apiIsLoading = false;
    return result;
  },
  async addFraudAiBulkSearchTermForProperty(propertyId, assignment) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/properties/fraudai/property/${propertyId}/searchterm/bulk/searchterm.json`,
      assignment,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Matching Rule(s)"});
    this.apiIsLoading = false;
    return result;
  }

}
