import prodNames from "@/util/prodNames";

export const Organization = [
  {
    path: "/app/organizations/add",
    name: "app.organization.add",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-organizations"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: {
              name: "app-home",
            },
          },
          {
            text: "Organizations",
            to: { name: "app.organization" },
          },
          {
            text: 'Add'
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-agents" */ "@/views/organization/OrganizationAddWizard.vue"
        ),
  },
  {
    path: "/app/organizations/:orgId/view/:view",
    name: "app.organization.detail.view",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-partner", "admin-partner-read"],
      breadCrumb(route) {
        const orgId = route?.params?.orgId;
        const view = route?.params?.view;
        return [
          {
            text: "Home",
            to: {
              name: "app-home",
            },
          },
          {
            text: "Organizations",
            to: {
              name: "app.organization",
            },
          },
          {
            text: orgId,
            to: {
              name: "app.organization.detail",
            }
          },
          {
            text: view,
            to: {
              name: "app.organization.detail.view",
            },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-organizations-detail" */ "@/views/organization/OrganizationDetailTabs.vue"
        ),
  },
  {
    path: "/app/organizations/:orgId",
    name: "app.organization.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-organizations", "admin-organizations-read"],
      breadCrumb(route) {
        const orgId = route.params.orgId;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Organizations",
            to: { name: "app.organization" },
          },
          {
            text: orgId,
            to: { name: "app.organization.detail" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-organizations-detail" */ "@/views/organization/OrganizationDetailTabs.vue"
        ),
  },
  {
    path: "/app/organizations",
    name: "app.organization",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-organizations", "admin-organizations-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: {name: "app-home"},
          },
          {
            text: "Organizations",
            to: {name: "app.organization"},
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-organizationsOrganizations" */ "@/views/organization/OrganizationsView.vue"
        )
  }
];
