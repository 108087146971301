import axios from "axios";
import {EventBus} from "@/event";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiDiscountGroups = {
  async getDiscountGroups(productId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/product/${productId}/discountGroups/discountGroups.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getDiscountGroupById(discountGroupId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/discountGroups/${discountGroupId}/discountGroup.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async createDiscountGroup(discountGroup) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(`${apiUrl}/auth/discountGroups/discountGroup.json`, discountGroup);
    EventBus.$emit("snackbar", {text: "DiscountGroup Created"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateDiscountGroup(discountGroupId, discountGroup) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/discountGroups/${discountGroupId}/discountGroup.json`,
      discountGroup
    );
    EventBus.$emit("snackbar", {text: "DiscountGroup Updated"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async deleteDiscountGroupById(discountGroupId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.delete(
      `${apiUrl}/auth/discountGroups/${discountGroupId}/discountGroup.json`
    );
    EventBus.$emit("snackbar", {text: "DiscountGroup Deleted"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getClientsForDiscountGroup(discountGroupId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/discountGroups/${discountGroupId}/clients.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async assignDiscountGroupToClient(discountGroupId, clientId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/discountGroups/${discountGroupId}/clients/${clientId}/discountGroup.json`
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Property Assigned"});
    this.apiIsLoading = false;
    return result;
  },
  async removeClientForDiscountGroup(discountGroupId, clientId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.delete(
      `${apiUrl}/auth/discountGroups/${discountGroupId}/clients/${clientId}/discountGroup.json`
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Client Un-Assigned"});
    this.apiIsLoading = false;
    return result;
  },
  async getClientsUnassignedToDiscountGroup() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/discountGroups/unassigned/clients.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getProductionForDiscountGroup(discountGroupId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/discountGroups/${discountGroupId}/production.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getProductionForDiscountGroupPeriodProperties(discountGroupId, yearMonth) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/discountGroups/${discountGroupId}/period/${yearMonth}/properties.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getProductionForDiscountGroupPeriodClients(discountGroupId, yearMonth) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/discountGroups/${discountGroupId}/period/${yearMonth}/clients.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  }
};
