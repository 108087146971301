import axios from "axios";
import {EventBus} from "@/event";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiPartners = {
  async getPartners() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/partners/partners.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPartnerById(partnerId) {
    this.apiIsLoading = true;
    let result;
    if (!partnerId) {
      EventBus.$emit("snackbar", {text: "No Valid Partner Assigned"});
      return {};
    }
    result = await axios.get(
      `${apiUrl}/auth/partners/${partnerId}/partner.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async createPartner(partner) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/partners/partner.json`,
      partner
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updatePartner(partnerId, partner) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/partners/${partnerId}/partner.json`,
      partner
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async assignPartnerToDeal(partnerId, dealId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/partners/${partnerId}/deals/${dealId}/partner.json`
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Deal Assigned"});
    this.apiIsLoading = false;
    return result;
  },
  async deletePartnerById(partnerId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.delete(
      `${apiUrl}/auth/partners/${partnerId}/partner.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPerformanceForPartner(partnerId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/partners/${partnerId}/performance/performance.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getUplineForPartner(partnerId) {
    this.apiIsLoading = true;
    let result;
    if (!partnerId) {
      EventBus.$emit("snackbar", {text: "No Valid Partner Assigned"});
      return [];
    }
    result = await axios.get(
      `${apiUrl}/auth/partners/${partnerId}/upline/partners.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getDownlineForPartner(partnerId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/partners/${partnerId}/downline/partners.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getDownlineFlattenedForPartner(partnerId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/partners/${partnerId}/downlineFlattened/partners.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPartnersWithPendingCommissions() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/partners/commissionsPending/partners.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPartnersUnassignedToClient() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/partners/unassignedClient/partners.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getClientsForPartner(partnerId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/partners/${partnerId}/clients/clients.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  }
};
