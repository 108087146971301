import axios from "axios";
import {EventBus} from "@/event";
export const apiUrl = import.meta.env.VITE_API_URL;
export const apiAdWords = {
  async getPropertyAdWordsLowBidCampaigns(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/${propertyId}/adwords/lbc/campaigns.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async addPropertyAdWordsRevvimLowBidCampaign(propertyId, payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/properties/${propertyId}/adwords/lbc/add/campaign.json`,
      payload
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Ad Campaign Link Created"});
    this.apiIsLoading = false;
    return result;
  },
  async removePropertyAdWordsRevvimLowBidCampaign(propertyId, payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/properties/${propertyId}/adwords/lbc/remove/campaign.json`,
      payload
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPropertyAdAiCampaigns(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/${propertyId}/adwords/adai/campaigns.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async addPropertyAdAiCampaign(propertyId, payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/properties/${propertyId}/adwords/adai/add/campaign.json`,
      payload
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Ad Campaign Link Created"});
    this.apiIsLoading = false;
    return result;
  },
  async removePropertyAdAiCampaign(propertyId, payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/properties/${propertyId}/adwords/adai/remove/campaign.json`,
      payload
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPropertyGscGeoCountries(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/${propertyId}/adwords/gsc/countries.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async addPropertyGscGeoCountry(propertyId, payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/properties/${propertyId}/adwords/gsc/add/country.json`,
      payload
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Ad Campaign Link Created"});
    this.apiIsLoading = false;
    return result;
  },
  async removePropertyGscGeoCountry(propertyId, payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/properties/${propertyId}/adwords/gsc/remove/country.json`,
      payload
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPropertyGeoLocations(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/${propertyId}/adwords/geo/locations.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async addPropertyGeoLocation(propertyId, payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/properties/${propertyId}/adwords/geo/add/location.json`,
      payload
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Ad Campaign Link Created"});
    this.apiIsLoading = false;
    return result;
  },
  async removePropertyGeoLocation(propertyId, payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/properties/${propertyId}/adwords/geo/remove/location.json`,
      payload
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
}
