import prodNames from "@/util/prodNames";

export const AdAi = [
    {
        path: "/adai",
        redirect: { name: "app.adai.properties" }
    },
    {
        path: "/app/adai/properties",
        name: "app.adai.properties",
        meta: {
            isWelcomeRoute: true,
            requiresAuth: true,
            prodName: prodNames.ADAI,
            permission: "adAi"
        },
        component: () => import(/* webpackChunkName: "AdAi-Properties" */ "@/views/adai/Properties.vue")
    },
    {
        path: "/app/adai/aggregate-report",
        name: "app.adai.aggregateReport",
        meta: {
            requiresAuth: true,
            prodName: prodNames.ADAI,
            permission: "adAi-aggregateReport"
        },
        component: () => import(/* webpackChunkName: "AdAi-AggregateReports" */ "@/views/adai/AggregateReport.vue")
    },
    {
        path: "/app/adai/property/add",
        name: "app.adai.propertyAdd",
        meta: {
            requiresAuth: true,
            prodName: prodNames.ADAI,
            permission: "adAi-addProperty"
        },
        component: () => import(/* webpackChunkName: "AdAi-PropertyAdd" */ "@/views/adai/PropertyAdd.vue")
    },
    {
        path: "/app/adai/property/:id/:tab?",
        name: "app.adai.propertyView",
        meta: {
            requiresAuth: true,
            prodName: prodNames.ADAI,
            permission: "adAi-viewProperty"
        },
        props(route) {
            const props = { ...route.params };
            props.id = +props.id;
            return props;
        },
        component: () => import(/* webpackChunkName: "AdAi-PropertyView" */ "@/views/adai/PropertyView.vue")
    },
]