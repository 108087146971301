import prodNames from "@/util/prodNames";

export const PartnerPayments = [
  {
    path: "/app/accounting/partnerpayments/:paymentId",
    name: "app.accounting.payment.detail",
    meta: {
      requiresAuth: true,
      department: 'accounting',
      prodName: prodNames.REVVIM,
      permission: ["admin-partner-payment", "admin-partner-payment-read"],
      breadCrumb(route) {
        const paymentId = route.params.paymentId;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Accounting",
            to: { name: "app.accounting" },
          },
          {
            text: "Partner Payments",
            to: { name: "app.accounting.payment" },
          },
          {
            text: paymentId,
            to: { name: "app.accounting.payment.detail" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-partnerpaymentsdetail" */ "@/views/accounting/partners/payment/PaymentDetail"
      ),
  },
  {
    path: "/app/accounting/partnerpayments",
    name: "app.accounting.payment",
    meta: {
      requiresAuth: true,
      department: 'accounting',
      prodName: prodNames.REVVIM,
      permission: ["admin-partner-payment", "admin-partner-payment-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Accounting",
            to: { name: "app.accounting" },
          },
          {
            text: "Partner Payments",
            to: { name: "app.accounting.payment" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-payments" */ "@/views/accounting/partners/payment/PaymentsView"
      ),
  },
];
