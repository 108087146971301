import axios from "axios";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiDeals = {
  async getDeals() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/pipedrive/deals/deals.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getDealById(dealId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/pipedrive/deals/${dealId}/deal.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getUplineForPerson(personId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/pipedrive/persons/${personId}/upline/upline.json`
    );
    result = result.data;
    this.apiIsLoading = false;

    return result;
  },
  async getAllDeals() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/pipedrive/deals/all_deals.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },

}
