import axios from "axios";
import {EventBus} from "@/event";

export const apiUrl = import.meta.env.VITE_API_URL;
export const apiReact = {
  async getReactPropertiesEnabledForClient(clientId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/react/client/${clientId}/enabled/properties.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateReactEnabledForProperty(assignment) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/admin/properties/react/enabled/action.json`,
      assignment
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Property Updated"});
    this.apiIsLoading = false;
    return result;
  },

}
