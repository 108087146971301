import axios from "axios";
import {EventBus} from "@/event";
export const apiUrl = import.meta.env.VITE_API_URL;
export const apiScheduledActions = {

  async getScheduledActionsForProperty(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/${propertyId}/scheduledActions/actions.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async addScheduledActionForProperty(propertyId, action) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/properties/${propertyId}/scheduledActions/action.json`,
      action
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Scheduled Action Added"});
    this.apiIsLoading = false;
    return result;
  },
  async updateScheduledActionForProperty(propertyId, action) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/admin/properties/${propertyId}/scheduledActions/${action.Id}/action.json`,
      action
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Scheduled Action Updated"});
    this.apiIsLoading = false;
    return result;
  },
  async deleteScheduledActionForProperty(propertyId, actionId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.delete(
      `${apiUrl}/auth/admin/properties/${propertyId}/scheduledActions/${actionId}/action.json`
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Scheduled Action Deleted"});
    this.apiIsLoading = false;
    return result;
  },

}
