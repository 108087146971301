<template>
  <v-breadcrumbs
    class="pt-0"
    v-if="breadCrumbs && breadCrumbs.length > 0"
    :items="breadCrumbs"
  >
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.to"
        class="text-subtitle-2"
        :disabled="item.disabled"
        exact
      >
        {{ routeLabel(item) }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>

import {propertyDetailViews} from "@/views/admin/property/propertyDetailViews";
import {clientDetailViews} from "@/views/accounting/clients/clientDetailViews";
import {partnerDetailViews} from "@/views/accounting/partners/partnerDetailViews";
import {organizationDetailViews} from "@/views/organization/organizationDetailViews";

export default {
  name: "Breadcrumb",
  computed: {
    breadCrumbs() {
      if (typeof this.$route.meta.breadCrumb === "function") {
        return this.$route.meta.breadCrumb.call(this, this.$route);
      }
      return this.$route.meta.breadCrumb;
    }
  },
  methods: {
    routeLabel(item) {
      if (item.to.name.includes('agent.detail.view')) {
        const view = propertyDetailViews.find(view => view.path === item.text)
        return view?.label || 'Detail'
      }
      if (item.to.name.includes('customer.detail.view')) {
        const view = clientDetailViews.find(view => view.path === item.text)
        return view?.label || 'General'
      }
      if (item.to.name.includes('partner.detail.view')) {
        const view = partnerDetailViews.find(view => view.path === item.text)
        return view?.label || 'General'
      }
      if (item.to.name.includes('organization.detail.view')) {
        const view = organizationDetailViews.find(view => view.path === item.text)
        return view?.label || 'General'
      }
      return item.text;
    }
  }
};
</script>
