import axios from "axios";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiUsers = {
  async getPipeDriveUsers() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/pipedrive/users/users.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPipeDriveUserById(orgId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/pipedrive/users/${orgId}/user.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  }
}
