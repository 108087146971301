import axios from "axios";
const apiUrl = import.meta.env.VITE_API_URL;

export const apiPartnerPortal = {
  async getPartnerId() {
    this.apiIsLoading = true;
    let partnerId;
    try {
      let result = await axios.get(
        `${apiUrl}/auth/partnerportal/users/partnerid.json`
      );
      partnerId = result.data.partnerId;
    } catch (error) {
      //throwing to the component
      throw error;
    } finally {
      this.apiIsLoading = false;
    }
    return partnerId;
  },
  async getPartnerIdByUserId(userId) {
    this.apiIsLoading = true;
    let data = {};
    try {
      let result = await axios.get(
        `${apiUrl}/auth/partnerportal/users/${userId}/partnerId.json`
      );
      data = result.data;
    } catch (error) {
      //throwing to the component
      throw error;
    } finally {
      this.apiIsLoading = false;
    }
    return data;
  },
  async getPartnerDataByUser(userId, timeRange) {
    this.apiIsLoading = true;
    let data = {};
    try {
      let result = await axios.get(
        `${apiUrl}/auth/partnerportal/users/${userId}/properties.json?startDate=${timeRange.startDate}&endDate=${timeRange.endDate}`
      );
      data = result.data;
    } catch (error) {
      //throwing to the component
      throw error;
    } finally {
      this.apiIsLoading = false;
    }
    return data;
  },
  async getPartnerDataByPartner(partnerId, timeRange) {
    this.apiIsLoading = true;
    let data = {};
    try {
      let result = await axios.get(
        `${apiUrl}/auth/partnerportal/partners/${partnerId}/properties.json?startDate=${timeRange.startDate}&endDate=${timeRange.endDate}`
      );
      data = result.data;
    } catch (error) {
      //throwing to the component
      throw error;
    } finally {
      this.apiIsLoading = false;
    }
    return data;
  },
  async getPartnerPortalDownline(partnerId, timeRange) {
    this.apiIsLoading = true;
    let data = {};
    try {
      let result = await axios.get(
        `${apiUrl}/auth/partnerportal/partners/${partnerId}/downline.json?startDate=${timeRange.startDate}&endDate=${timeRange.endDate}`
      );
      data = result.data;
    } catch (error) {
      //throwing to the component
      throw error;
    } finally {
      this.apiIsLoading = false;
    }
    return data;
  },
  async getPartnerDealsByPartner(partnerId, timeRange) {
    this.apiIsLoading = true;
    let data = {};
    try {
      let result = await axios.get(
        `${apiUrl}/auth/partnerportal/partners/${partnerId}/deals.json?startDate=${timeRange.startDate}&endDate=${timeRange.endDate}`
      );
      data = result.data;
    } catch (error) {
      //throwing to the component
      throw error;
    } finally {
      this.apiIsLoading = false;
    }
    return data;
  },
  async getPartnerDealsByUser(userId, timeRange) {
    this.apiIsLoading = true;
    let data = {};
    try {
      let result = await axios.get(
        `${apiUrl}/auth/partnerportal/users/${userId}/deals.json?startDate=${timeRange.startDate}&endDate=${timeRange.endDate}`
      );
      data = result.data;
    } catch (error) {
      //throwing to the component
      throw error;
    } finally {
      this.apiIsLoading = false;
    }
    return data;
  },
  async getDealProperties(userId, dealId, timeRange) {
    this.apiIsLoading = true;
    let data = {};
    try {
      let result = await axios.get(
        `${apiUrl}/auth/partnerportal/users/${userId}/deals/${dealId}/properties.json?startDate=${timeRange.startDate}&endDate=${timeRange.endDate}`
      );
      data = result.data;
    } catch (error) {
      //throwing to the component
      throw error;
    } finally {
      this.apiIsLoading = false;
    }
    return data;
  },
  async getEmailProperties(email, timeRange) {
    this.apiIsLoading = true;
    let data = {};
    let range = "";
    if (timeRange) {
      let range = `?startDate=${timeRange.startDate}&endDate=${timeRange.endDate}`;
    }
    try {
      let result = await axios.get(
        `${apiUrl}/auth/partnerportal/email/${email}/properties.json${range}`
      );
      data = result.data;
    } catch (error) {
      //throwing to the component
      throw error;
    } finally {
      this.apiIsLoading = false;
    }
    return data;
  },
  async getPartnerPortalDetailById(partnerId) {
    this.apiIsLoading = true;
    let result;
    try {
      result = await axios.get(
        `${apiUrl}/auth/partnerportal/partners/${partnerId}/partner.json`
      );
      result = result.data;
    } catch (error) {
      //throwing to the component
      throw error;
    } finally {
      this.apiIsLoading = false;
    }
    return result;
  },
  async getPartnerCommissionsByUser(userId, timeRange) {
    this.apiIsLoading = true;
    let data = {};
    try {
      let result = await axios.get(
        `${apiUrl}/auth/partnerportal/users/${userId}/commissions.json?startDate=${timeRange.startDate}&endDate=${timeRange.endDate}`
      );
      data = result.data;
    } catch (error) {
      //throwing to the component
      throw error;
    } finally {
      this.apiIsLoading = false;
    }
    return data;
  },
  async getPartnerPortalCommissionsForPartner(partnerId) {
    this.apiIsLoading = true;
    let result;
    try {
      result = await axios.get(
        `${apiUrl}/auth/partnerportal/commissions/partner/${partnerId}/commissions.json`
      );
      result = result.data;
    } catch (error) {
      //throwing to the component
      throw error;
    } finally {
      this.apiIsLoading = false;
    }
    return result;
  },
  async getPartnerPortalPayments(partnerId) {
    this.apiIsLoading = true;
    let result;
    try {
      result = await axios.get(
        `${apiUrl}/auth/partnerportal/partner/${partnerId}/payments.json`
      );
      result = result.data;
    } catch (error) {
      //throwing to the component
      throw error;
    } finally {
      this.apiIsLoading = false;
    }
    return result;
  },
  async getPartnerPortalPaymentById(paymentId) {
    this.apiIsLoading = true;
    let result;
    try {
      result = await axios.get(
        `${apiUrl}/auth/partnerportal/payments/${paymentId}/payment.json`
      );
      result = result.data;
    } catch (error) {
      //throwing to the component
      throw error;
    } finally {
      this.apiIsLoading = false;
    }
    return result;
  },
  async getPartnerPortalUnpaidCommissions(partnerId) {
    this.apiIsLoading = true;
    let result;
    try {
      result = await axios.get(
        `${apiUrl}/auth/partnerportal/partner/${partnerId}/unpaid/commissions.json`
      );
      result = result.data;
    } catch (error) {
      //throwing to the component
      throw error;
    } finally {
      this.apiIsLoading = false;
    }
    return result;
  },
  async getPartnerPortalCommissionById(commissionId) {
    this.apiIsLoading = true;
    let result;
    try {
      result = await axios.get(
        `${apiUrl}/auth/partnerportal/commissions/${commissionId}/commission.json`
      );
      result = result.data;
    } catch (error) {
      //throwing to the component
      throw error;
    } finally {
      this.apiIsLoading = false;
    }
    return result;
  },
  async getPartnerPortalProductionPeriods() {
    this.apiIsLoading = true;
    let result;
    try {
      result = await axios.get(
        `${apiUrl}/auth/partnerportal/production/periods.json`
      );
      result = result.data;
    } catch (error) {
      //throwing to the component
      throw error;
    } finally {
      this.apiIsLoading = false;
    }
    return result;
  },
  async getPartnerPortalClientsForPartner(partnerId) {
    this.apiIsLoading = true;
    let result;
    try {
      result = await axios.get(
        `${apiUrl}/auth/partnerportal/partners/${partnerId}/clients/clients.json`
      );
      result = result.data;
    } catch (error) {
      //throwing to the component
      throw error;
    } finally {
      this.apiIsLoading = false;
    }
    return result;
  },
  async getPartnerPortalDownlineFlattened(partnerId, dateRange) {
    this.apiIsLoading = true;
    let result;
    try {
      result = await axios.get(
        `${apiUrl}/auth/partnerportal/partners/${partnerId}/downlineFlattened/partners.json`
      );
      result = result.data;
    } catch (error) {
      //throwing to the component
      throw error;
    } finally {
      this.apiIsLoading = false;
    }
    return result;
  },
  async getPartnerPortalDataRequests(partnerId) {
    this.apiIsLoading = true;
    let result;
    try {
      result = await axios.get(
        `${apiUrl}/auth/partnerportal/partners/${partnerId}/datarequests/requests.json`
      );
      result = result.data;
    } catch (error) {
      //throwing to the component
      throw error;
    } finally {
      this.apiIsLoading = false;
    }
    return result;
  },
  async submitDataRequest(partnerId, properties, dataType, item) {
    this.apiIsLoading = true;
    let result;
    let seoResult;
    try {
      const partner = await this.getPartnerPortalDetailById(item.Id);
      result = await axios.post(
        `${apiUrl}/auth/partnerportal/partners/${partnerId}/dataRequest/submission.json`,
        {
          properties,
          dataType,
          partner: partner[0]
        }
      );
      result = result.data;

      // seoResult = await axios.post(`https://seo.revvim.com/referrer_req`, {
      //   partnerId,
      //   properties,
      //   dataType
      // });
    } catch (error) {
      //throwing to the component
      throw error;
    } finally {
      this.apiIsLoading = false;
    }
    return result;
  }
};
