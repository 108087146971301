import prodNames from "@/util/prodNames";
import { productLabelById } from "@/util/prodNames";

export const Product = [
  {
    path: "/app/products/:productId/discountgroups",
    name: "app.product.discountGroup",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-client", "admin-client-read"],
      breadCrumb(route) {
        const productId = route.params.productId;
        return [
          {
            text: "Home",
            to: { name: "app-home" }
          },
          {
            text: "Products",
            to: { name: "app.product" }
          },
          {
            text: productLabelById(productId),
            to: { name: "app.product.detail" }
          },
          {
            text: "Discount Groups",
            to: { name: "app.product.discountGroup" }
          }
        ];
      }
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-productDetail" */ "@/views/products/common/clients/discountGroups/DiscountGroupsView.vue"
      )
  },
  {
    path:
      "/app/products/:productId/customers/:clientId/agents/:propertyId/rules/:ruleId",
    name: "app.product.customer.property.reactRule.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-client", "admin-client-read"],
      breadCrumb(route) {
        const productId = route.params.productId;
        const clientId = route.params.clientId;
        const propertyId = route.params.propertyId;
        const ruleId = route.params.ruleId;
        return [
          {
            text: "Home",
            to: { name: "app-home" }
          },
          {
            text: "Products",
            to: { name: "app.product" }
          },
          {
            text: productLabelById(productId),
            to: { name: "app.product.detail" }
          },
          {
            text: "Customers",
            to: { name: "app.product.customer" }
          },
          {
            text: clientId,
            to: { name: "app.product.customer.detail" }
          },
          {
            text: "Agents",
            to: { name: "app.product.customer.detail" }
          },
          {
            text: propertyId,
            to: { name: "app.product.customer.property.detail" }
          },
          {
            text: "Rules",
            to: { name: "app.product.customer.property.detail" }
          },
          {
            text: ruleId,
            to: { name: "app.product.customer.property.reactRule.detail" }
          }
        ];
      }
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-productClientPropertyDetail" */ "@/views/products/common/clients/properties/ProductCommonClientPropertyDetail.vue"
      )
  },
  {
    path: "/app/products/:productId/customers/:clientId/agents/:propertyId",
    name: "app.product.customer.property.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-client", "admin-client-read"],
      breadCrumb(route) {
        const productId = route.params.productId;
        const clientId = route.params.clientId;
        const propertyId = route.params.propertyId;
        return [
          {
            text: "Home",
            to: { name: "app-home" }
          },
          {
            text: "Products",
            to: { name: "app.product" }
          },
          {
            text: productLabelById(productId),
            to: { name: "app.product.detail" }
          },
          {
            text: "Customers",
            to: { name: "app.product.customer" }
          },
          {
            text: clientId,
            to: { name: "app.product.customer.detail" }
          },
          {
            text: "Agents",
            to: { name: "app.product.customer.detail" }
          },
          {
            text: propertyId,
            to: { name: "app.product.customer.detail" }
          }
        ];
      }
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-productClientPropertyDetail" */ "@/views/products/common/clients/properties/ProductCommonClientPropertyDetail.vue"
      )
  },
  {
    path: "/app/products/:productId/customers/:clientId",
    name: "app.product.customer.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-client", "admin-client-read"],
      breadCrumb(route) {
        const clientId = route.params.clientId;
        const productId = route.params.productId;
        console.log("breadcrumb ->", route);
        return [
          {
            text: "Home",
            to: { name: "app-home" }
          },
          {
            text: "Products",
            to: { name: "app.product" }
          },
          {
            text: productLabelById(productId),
            to: { name: "app.product.detail" }
          },
          {
            text: "Customers",
            to: { name: "app.product.customer" }
          },
          {
            text: clientId,
            to: { name: "app.product.customer.detail" }
          }
        ];
      }
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-productClientDetail" */ "@/views/products/common/clients/ProductCommonClientDetail.vue"
      )
  },
  {
    path: "/app/products/:productId/customers",
    name: "app.product.customer",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-client", "admin-client-read"],
      breadCrumb(route) {
        const productId = route.params.productId;
        return [
          {
            text: "Home",
            to: { name: "app-home" }
          },
          {
            text: "Products",
            to: { name: "app.product" }
          },
          {
            text: productLabelById(productId),
            to: { name: "app.product.detail" }
          },
          {
            text: "Customers",
            to: { name: "app.product.customer" }
          }
        ];
      }
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-productDetailClients" */ "@/views/products/common/ProductDetailClientsTable"
      )
  },
  {
    path: "/app/products/:productId",
    name: "app.product.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-client", "admin-client-read"],
      breadCrumb(route) {
        const productId = route.params.productId;
        return [
          {
            text: "Home",
            to: { name: "app-home" }
          },
          {
            text: "Products",
            to: { name: "app.product" }
          },
          {
            text: productLabelById(productId),
            to: { name: "app.product.detail" }
          }
        ];
      }
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-productDetail" */ "@/views/products/common/ProductDetailTabs"
      )
  },
  {
    path: "/app/products",
    name: "app.product",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-client", "admin-client-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: { name: "app-home" }
          },
          {
            text: "Products",
            to: { name: "app.product" }
          }
        ];
      }
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-products" */ "@/views/products/common/Products"
      )
  },
  {
    path: "/app/products/:productId/leadlist",
    name: "app.product.leadlist",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-client", "admin-client-read"],
      breadCrumb(route) {
        const productId = route.params.productId;
        return [
          {
            text: "Home",
            to: { name: "app-home" }
          },
          {
            text: "Products",
            to: { name: "app.product" }
          },
          {
            text: productLabelById(productId),
            to: { name: "app.product.detail" }
          },
          {
            text: "Lead List",
            to: { name: "app.product.leadlist" }
          }
        ];
      }
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-products" */ "@/views/products/react/ReactLeadList.vue"
      )
  }
];
