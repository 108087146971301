import axios from "axios";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiGoals = {
  async getPipeDriveGoals() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/pipedrive/goals/goals.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPipeDriveGoalById(id) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/pipedrive/goals/${id}/goal.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  }
}
