import prodNames from "@/util/prodNames";

export const Sales = [
  {
    path: "/app/sales",
    name: "app.sales",
    meta: {
      requiresAuth: true,
      department: 'sales',
      prodName: prodNames.REVVIM,
      permission: [
        "admin-client",
        "admin-client-read"
      ],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Sales",
            to: { name: "app.sales" },
          }
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-clients" */ "@/views/sales/SalesDashboard.vue"
        ),
  },
  {
    path: "/app/sales/customers/:clientId/view/:view",
    name: "app.sales.customer.detail.view",
    meta: {
      requiresAuth: true,
      department: 'sales',
      prodName: prodNames.REVVIM,
      permission: ["admin-client", "admin-client-read"],
      breadCrumb(route) {
        const clientId = route?.params?.clientId;
        const view = route?.params?.view;
        return [
          {
            text: "Home",
            to: {
              name: "app-home",
            },
          },
          {
            text: "Sales",
            to: {
              name: "app.sales",
            },
          },
          {
            text: "Customers",
            to: {
              name: "app.sales.customer",
            },
          },
          {
            text: clientId,
            to: {
              name: "app.sales.customer.detail",
            }
          },
          {
            text: view,
            to: {
              name: "app.sales.customer.detail.view",
            },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-clients" */ "@/views/accounting/clients/ClientDetailTabs.vue"
        ),
  },
  {
    path: "/app/sales/customers/:clientId",
    name: "app.sales.customer.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'sales',
      permission: ["admin-client", "admin-client-read"],
      breadCrumb(route) {
        const clientId = route.params.clientId;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Sales",
            to: { name: "app.sales" },
          },
          {
            text: "Customers",
            to: { name: "app.sales.customer" },
          },
          {
            text: clientId,
            to: { name: "app.sales.customer.detail" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-clients" */ "@/views/accounting/clients/ClientDetailTabs.vue"
        ),
  },
  {
    path: "/app/sales/customers",
    name: "app.sales.customer",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'sales',
      permission: ["admin-client", "admin-client-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Sales",
            to: { name: "app.sales" },
          },
          {
            text: "Customers",
            to: { name: "app.sales.customer" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-clients" */ "@/views/accounting/clients/ClientsView"
        ),
  }
];
