import axios from "axios";
import {EventBus} from "@/event";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiWorkOrders = {
  async getWorkOrders() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/workOrders/workOrders.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getWorkOrderById(workOrderId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/workOrders/${workOrderId}/workOrder.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },

  async getWorkOrderProductionPeriods() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/workOrders/workOrderPeriods.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getWorkOrdersByPeriod(yearMonth) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/workOrders/period/${yearMonth}/workOrder.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getWorkOrdersByClientId(clientId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/workOrders/clients/${clientId}/workOrderPeriods.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },

  async updateWorkOrderInvoicedStatus(workOrderId, item) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/workOrders/${workOrderId}/invoiced/invoice.json`,
      item
    );
    EventBus.$emit("snackbar", {text: "Updated"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },

  // async createWorkOrder(workOrder) {
  //     this.apiIsLoading = true;
  //     let result;
  //     try {
  //         result = await axios.post(`${apiUrl}/auth/workOrders/workOrder.json`, workOrder);
  //         EventBus.$emit("snackbar", { text: "WorkOrder Created" });
  //         result = result.data;
  //     } catch (error) {
  //         //throwing to the component
  //         throw error;
  //     } finally {
  //         this.apiIsLoading = false;
  //     }
  //     return result;
  // },
  // async updateWorkOrder(workOrderId, workOrder) {
  //     this.apiIsLoading = true;
  //     let result;
  //     try {
  //         result = await axios.put(
  //             `${apiUrl}/auth/workOrders/${workOrderId}/workOrder.json`,
  //             workOrder
  //         );
  //         EventBus.$emit("snackbar", { text: "WorkOrder Updated" });
  //         result = result.data;
  //     } catch (error) {
  //         //throwing to the component
  //         throw error;
  //     } finally {
  //         this.apiIsLoading = false;
  //     }
  //     return result;
  // },
  // async deleteWorkOrderById(workOrderId) {
  //     this.apiIsLoading = true;
  //     let result;
  //     try {
  //         result = await axios.delete(
  //             `${apiUrl}/auth/workOrders/${workOrderId}/workOrder.json`
  //         );
  //         EventBus.$emit("snackbar", { text: "WorkOrder Deleted" });
  //         result = result.data;
  //     } catch (error) {
  //         //throwing to the component
  //         throw error;
  //     } finally {
  //         this.apiIsLoading = false;
  //     }
  //     return result;
  // },
  //
  // async getItemsForWorkOrder(workOrderId) {
  //     this.apiIsLoading = true;
  //     let result;
  //     try {
  //         result = await axios.get(`${apiUrl}/auth/workOrders/${workOrderId}/items.json`);
  //         result = result.data;
  //     } catch (error) {
  //         //throwing to the component
  //         throw error;
  //     } finally {
  //         this.apiIsLoading = false;
  //     }
  //     return result;
  // },
  // async recalculateWorkOrderPeriod(workOrderId, yearMonth) {
  //     this.apiIsLoading = true;
  //     let result;
  //     try {
  //         result = await axios.put(
  //             `${apiUrl}/auth/workOrders/${workOrderId}/period/${yearMonth}/recalculate.json`
  //         );
  //         EventBus.$emit("snackbar", { text: "WorkOrder Production Updated" });
  //         result = result.data;
  //     } catch (error) {
  //         //throwing to the component
  //         throw error;
  //     } finally {
  //         this.apiIsLoading = false;
  //     }
  //     return result;
  // },
  //
  // async createWorkOrderPayment(payment) {
  //     this.apiIsLoading = true;
  //     let result;
  //     try {
  //         result = await axios.post(`${apiUrl}/auth/workOrders/payment.json`, payment);
  //         EventBus.$emit("snackbar", { text: "Payment Created" });
  //         result = result.data;
  //     } catch (error) {
  //         //throwing to the component
  //         throw error;
  //     } finally {
  //         this.apiIsLoading = false;
  //     }
  //     return result;
  // },
  // async deleteWorkOrderPaymentById(workOrderId, paymentId) {
  //     this.apiIsLoading = true;
  //     let result;
  //     try {
  //         result = await axios.delete(
  //             `${apiUrl}/auth/workOrders/${workOrderId}/payments/${paymentId}/payment.json`
  //         );
  //         EventBus.$emit("snackbar", { text: "Payment Deleted" });
  //         result = result.data;
  //     } catch (error) {
  //         //throwing to the component
  //         throw error;
  //     } finally {
  //         this.apiIsLoading = false;
  //     }
  //     return result;
  // },
  // async getWorkOrdersPayments() {
  //     this.apiIsLoading = true;
  //     let result;
  //     try {
  //         result = await axios.get(`${apiUrl}/auth/workOrders/payments.json`);
  //         result = result.data;
  //     } catch (error) {
  //         //throwing to the component
  //         throw error;
  //     } finally {
  //         this.apiIsLoading = false;
  //     }
  //     return result;
  // },
  // async getWorkOrdersPaymentsByWorkOrderId(workOrderId) {
  //     this.apiIsLoading = true;
  //     let result;
  //     try {
  //         result = await axios.get(
  //             `${apiUrl}/auth/workOrders/${workOrderId}/payments.json`
  //         );
  //         result = result.data;
  //     } catch (error) {
  //         //throwing to the component
  //         throw error;
  //     } finally {
  //         this.apiIsLoading = false;
  //     }
  //     return result;
  // },
  // async updateWorkOrderPaymentNotes(workOrderId, item) {
  //     this.apiIsLoading = true;
  //     let result;
  //     try {
  //         result = await axios.put(
  //             `${apiUrl}/auth/workOrders/${workOrderId}/payment/notes.json`,
  //             item
  //         );
  //         EventBus.$emit("snackbar", { text: "Notes Updated" });
  //         result = result.data;
  //     } catch (error) {
  //         //throwing to the component
  //         throw error;
  //     } finally {
  //         this.apiIsLoading = false;
  //     }
  //     return result;
  // }
};
