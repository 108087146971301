import axios from "axios";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiAgency = {
  async getAgencies() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/agencies/agencies.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getAgenciesById(agencyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/agencies/${agencyId}/agency.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  }
};
