<template>
    <v-toolbar-items>
        <v-btn active-class="v-btn-cancel-active" variant="text" v-show="!user.signedIn && $route.name !== 'app.login'" :to="{name: 'app.login'}">{{$t("nav.login")}}</v-btn>

        <v-menu
          transition="slide-y-transition"
          offset-y
        >
          <template v-slot:activator="{ props }">
            <v-btn v-show="user.signedIn" color="white" elevation="0" v-bind="props">
              <v-avatar size="30" v-if="userPicture" :image="userPicture" />
              <v-avatar size="30" v-else color="indigo">
                <v-icon  color="white">mdi-account</v-icon>
              </v-avatar>
<!--              <v-icon class="ml-2" color="grey">mdi-menu</v-icon>-->
            </v-btn>
          </template>


          <v-list min-width="250">
            <v-list-item>

                <!--<v-list-item-title>{{userName}}</v-list-item-title>-->
                <v-list-item-title>{{userEmail}}</v-list-item-title>
                <!--<v-list-item-subtitle>{{userCompany}}</v-list-item-subtitle>-->

            </v-list-item>

<!--            <span v-if="env !== 'production'">-->
<!--              <v-divider></v-divider>-->
<!--              <v-list-item @click.stop>-->
<!--                <v-select-->
<!--                  :items="locales"-->
<!--                  :label="$t('general.language')"-->
<!--                  v-model="locale"-->
<!--                  class="my-3"-->
<!--                  hide-details-->
<!--                  variant="underlined"-->
<!--                  density="compact"-->
<!--                ></v-select>-->
<!--              </v-list-item>-->
<!--            </span>-->

            <v-divider></v-divider>

<!--            <v-list-item :to="{name:'app.partnerPortal'}" v-if="hasPermission(['Partner','PartnerPortal'])">Partner Portal</v-list-item>-->
<!--            <v-list-item :to="{name:'app.accountSettings'}" v-if="!isPartnerNav">{{$t('profile.accountSettings')}}</v-list-item>-->

            <v-list-item :to="{ name:'app.logout' }">{{$t('login.signOut')}}</v-list-item>

          </v-list>
        </v-menu>

    </v-toolbar-items>
</template>


<script>
import { mapState } from 'pinia';
import { useAppStore } from '@/stores/app';
import {api} from "@/api";

export default {
  data: () => ({
    locales: [{title:"English",value:"en"}, {title:"Español",value:"es"}],
    env: process.env.NODE_ENV
  }),
  props:{
    isPartnerNav:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(useAppStore, [
        'user',
        'cognito'
    ]),
    locale:{
      get(){
        return this?.$store?.locale;
      },
      set(value){
        this.$store.setLocale(value);
      }
    },
    userName(){
      return this.cognito ? this.cognito.attributes.name : '';
    },
    userEmail(){
      return this.cognito ? this.cognito.attributes.email : '';
    },
    userCompany(){
      return this.cognito ? this.cognito.attributes['custom:company'] : '';
    },
    userPicture(){
      return this.cognito ? this.cognito.attributes.picture : '';
    },
    hasProperties(){
      return this.user.properties && this.user.properties.length;
    }
  },
  methods: {
    async reset(){
      await this.resetCache();
    },
  },
  mixins: [api],
  watch:{
    locale(newLocale){
      this.$i18n.locale = newLocale;
    }
  }
}
</script>
