import axios from "axios";
import {EventBus} from "@/event";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiDeals = {
  async getPerformanceForDeal(dealId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/deals/${dealId}/performance/performance.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getDealsForPartner(partnerId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/deals/partner/${partnerId}/deals.json`
    );
    result = result.data;
    this.apiIsLoading = false;

    return result;
  },
  async getDealsUnassignedToPartner() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/deals/unassigned/deals.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getDealPartnerSettings(dealId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/deals/${dealId}/partnerSettings.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateDealPartnerSettings(dealId, partnerId, settings) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/deals/${dealId}/partners/${partnerId}/partnerSettings.json`,
      settings
    );
    EventBus.$emit("snackbar", {text: "Partner Settings Updated"});
    result = result.data;
    this.apiIsLoading = false;

    return result;
  },
};
