import prodNames from "@/util/prodNames";

export const DealCs = [
  {
    path: "/app/cs/deals/:dealId",
    name: "app.cs.deal.detail",
    meta: {
      requiresAuth: true,
      department: 'cs',
      prodName: prodNames.REVVIM,
      permission: ["admin-deal", "admin-deal-read"],
      breadCrumb(route) {
        const dealId = route.params.dealId;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Customer Success",
            to: { name: "app.cs" },
          },
          {
            text: "Deals",
            to: { name: "app.cs.deal" },
          },
          {
            text: dealId,
            to: { name: "app.cs.deal.detail" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-deals-detail" */ "@/views/sales/deals/DealDetailTabs"
      ),
  },
  {
    path: "/app/cs/deals",
    name: "app.cs.deal",
    meta: {
      requiresAuth: true,
      department: 'cs',
      prodName: prodNames.REVVIM,
      permission: ["admin-deal", "admin-deal-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Customer Success",
            to: { name: "app.cs" },
          },
          {
            text: "Deals",
            to: { name: "app.cs.deal" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-deals" */ "@/views/sales/deals/DealsView"
      ),
  },
];
