// Translation provided by Vuetify (javascript)
import { en as vuetify_en, es as vuetify_es } from 'vuetify/locale'
import { createI18n } from 'vue-i18n'

import en_US from '../../locales/en-US/translation.json'
import es_ES from '../../locales/es/translation.json'
import helpContent_en from '../../locales/en-US/help-content.js'
import helpContent_es from '../../locales/es/help-content.js'

const messages = {
    /* ENGLISH */
    en: {
        ...en_US,
        $vuetify: {
            ...vuetify_en
        },
        helpContent: {
            ...helpContent_en
        }
    },
    /* SPANISH */
    es: {
        ...es_ES,
        $vuetify: {
            ...vuetify_es
        },
        helpContent: {
            ...helpContent_es
        }
    },
}

const i18n = createI18n({
    legacy: false,
    warnHtmlMessage: false,
    locale: 'en',
    messages, 
});

export default i18n;
  