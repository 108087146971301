import {Accounting} from "@/routes/accounting/Accounting";
import {Admin} from "@/routes/admin";
import {AdAi} from "@/routes/product/AdAdi";
import {Agencies} from "@/routes/sales/Agencies";
import {Sales} from "@/routes/sales/Sales";
import {Core} from "@/routes/Core";
import {CustomerSuccess} from "@/routes/cs/CustomerSuccess";
import {Client} from "@/routes/accounting/Client";
import {Commission} from "@/routes/accounting/Commission";
import {AgentAccounting} from "@/routes/accounting/Agent";
import {Deal} from "@/routes/sales/Deal";
import {Report} from "@/routes/Report";
import {Partner} from "@/routes/sales/Partner";
import {PartnerPortal} from "@/routes/PartnerPortal";
import {PartnerPayments} from "@/routes/accounting/PartnerPayments";
import {Product} from "@/routes/product/Product";
import {Production} from "@/routes/accounting/Production";
import {React} from "@/routes/product/React";
import {WorkOrder} from "@/routes/accounting/WorkOrder";
import prodNames from "@/util/prodNames";
import {AgentSales} from "@/routes/sales/Agent"
import {AgentCs} from "@/routes/cs/Agent"
import {DealCs} from "@/routes/cs/Deal";
import {PartnerCs} from "@/routes/cs/Partner";
import {Organization} from "@/routes/organization/Organization";


export const Routes = [
  {
    path: "/app/home",
    name: "app-home",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: [
        "admin-authenticated-read",
        "admin-authenticated"
      ],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: {
              name: "app-home",
            },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-agents" */ "@/views/home/HomeDashboard.vue"
        ),
  },
  ...Admin,
  ...Accounting,
  ...AgentAccounting,
  ...AdAi,
  ...Agencies,
  ...Sales,
  ...Client,
  ...Commission,
  ...Core,
  ...CustomerSuccess,
  ...Deal,
  ...Organization,
  ...Partner,
  ...PartnerPortal,
  ...PartnerPayments,
  ...Product,
  ...Production,
  ...React,
  ...Report,
  ...WorkOrder,
  ...AgentSales,
  ...AgentCs,
  ...DealCs,
  ...PartnerCs
];
