import axios from "axios";
import {EventBus} from "@/event";
import {apiAdGroups} from "@/api/admin/properties/ApiAdGroups.js";
import {apiAdWords} from "@/api/admin/properties/ApiAdWords.js";
import {apiDeal} from "@/api/admin/properties/ApiDeal.js";
import {apiReact} from "@/api/admin/properties/ApiReact.js";
import {apiFraudAi} from "@/api/admin/properties/ApiFraudAi.js";
import {apiScheduledActions} from "@/api/admin/properties/ApiScheduledActions.js";
import {apiUsers} from "@/api/admin/properties/ApiUsers.js";
import {apiEventLog} from "@/api/admin/properties/ApiEventLog.js";
import {apiKeywordTartgeting} from "@/api/admin/properties/ApiKeywordTargetting.js";
import {apiNonCompeteDomains} from "@/api/admin/properties/ApiNonCompeteDomains.js";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiProperties = {
  ...apiAdGroups,
  ...apiAdWords,
  ...apiDeal,
  ...apiFraudAi,
  ...apiReact,
  ...apiScheduledActions,
  ...apiUsers,
  ...apiEventLog,
  ...apiKeywordTartgeting,
  ...apiNonCompeteDomains,
  async getPropertiesAdmin() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/properties.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async addPropertyAdmin(property) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/properties/property.json`,
      property
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Property Added"}); // this.$t('property.propertyDeleted')
    this.apiIsLoading = false;
    return result;
  },
  async updatePropertyAdmin(property) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/admin/properties/${property.Id}/property.json`,
      property
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Property Updated"}); // this.$t('property.propertyDeleted')
    this.apiIsLoading = false;
    return result;
  },
  async getPropertyByID(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/${propertyId}/property.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPropertyConfigInfoByID(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/${propertyId}/config/dataset.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPropertyMonthlyReclaimedBillables(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/${propertyId}/reclaimedBillables/billables.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updatePropertyMonthlyReclaimedBillableStatus(propertyId, status) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/admin/properties/${propertyId}/reclaimedBillables/status.json`,
      status
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Status Updated"}); // this.$t('property.propertyDeleted')
    this.apiIsLoading = false;
    return result;
  },
  async getPropertyActionsAvailableForProperty(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/${propertyId}/propertyActions/actions.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getEventsForProperty(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/${propertyId}/events/events.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPropertiesAssignedToClient(clientId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/client/${clientId}/properties.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  getPropertyActionStatusText(item) {
    switch (item.Status) {
      case 1:
        return item.DateCompleted ? "Completed" : "Scheduled";
    }
    return "Error";
  },
  getPropertyEventStatusText(item) {
    switch (item.Status) {
      case 1:
        return "Completed";
    }
    return "Error";
  },
  async getPropertyDomains(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/${propertyId}/domains.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
};
