import prodNames from "@/util/prodNames";

export const AgentSales = [
  {
    path: "/app/sales/agents/add",
    name: "app.sales.adai.property.add",
    meta: {
      requiresAuth: true,
      department: 'admin',
      prodName: prodNames.REVVIM,
      permission: ["admin-property"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: {
              name: "app-home",
            },
          },
          {
            text: "Sales",
            to: {
              name: "app.sales",
            },
          },
          {
            text: "Agents",
            to: {
              name: "app.admin.agent",
            },
          },
          {
            text: 'Add Agent',
            to: {
              name: "app.sales.adai.property.add",
            },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-agents" */ "@/views/adai/PropertyAdd.vue"
        ),
  },
  {
    path: "/app/sales/agents/:propertyId/view/:view",
    name: "app.sales.agent.detail.view",
    meta: {
      requiresAuth: true,
      department: 'cs',
      prodName: prodNames.REVVIM,
      permission: ["admin-property", "admin-property-read"],
      breadCrumb(route) {
        const propertyId = route?.params?.propertyId;
        const view = route?.params?.view;
        return [
          {
            text: "Home",
            to: {
              name: "app-home",
            },
          },
          {
            text: "Sales",
            to: {
              name: "app.sales",
            },
          },
          {
            text: "Agents",
            to: {
              name: "app.sales.agent",
            },
          },
          {
            text: propertyId,
            to: {
              name: "app.sales.agent.detail",
            },
          },
          {
            text: view,
            to: {
              name: "app.sales.agent.detail.view",
            },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-agents" */ "@/views/admin/property/PropertyDetailTabs.vue"
        ),
  },
  {
    path: "/app/sales/agents/:propertyId",
    name: "app.sales.agent.detail",
    meta: {
      requiresAuth: true,
      department: 'sales',
      prodName: prodNames.REVVIM,
      permission: ["admin-property", "admin-property-read"],
      breadCrumb(route) {
        const propertyId = route?.params?.propertyId;
        return [
          {
            text: "Home",
            to: {
              name: "app-home",
            },
          },
          {
            text: "Sales",
            to: {
              name: "app.sales",
            },
          },
          {
            text: "Agents",
            to: {
              name: "app.sales.agent",
            },
          },
          {
            text: propertyId,
            to: {
              name: "app.sales.agent.detail",
            },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Sales-agents" */ "@/views/admin/property/PropertyDetailTabs.vue"
      ),
  },
  {
    path: "/app/sales/agents",
    name: "app.sales.agent",
    meta: {
      requiresAuth: true,
      department: 'sales',
      prodName: prodNames.REVVIM,
      permission: ["admin-property", "admin-property-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: {
              name: "app-home",
            },
          },
          {
            text: "Sales",
            to: {
              name: "app.sales",
            },
          },
          {
            text: "Agents",
            to: {
              name: "app.sales.agent",
            },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Sales-agents" */ "@/views/admin/property/PropertyView"
      ),
  },
];
