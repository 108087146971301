import axios from "axios";
import {EventBus} from "@/event";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiProducts = {
  async getProducts() {
    this.apiIsLoading = true;
    const result = await axios.get(`${apiUrl}/auth/admin/products/products.json`);
    this.apiIsLoading = false;
    return result;
  },
  async createProduct(product) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/products/product.json`,
      product
    );
    EventBus.$emit("snackbar", {text: "Product Created"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateProduct(productId, product) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/admin/products/${productId}/product.json`,
      product
    );
    EventBus.$emit("snackbar", {text: "Product Updated"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getClientsForProduct(productId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/products/${productId}/clients.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async assignClientToProduct(clientId, productId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/products/${productId}/clients/${clientId}/client.json`
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Product Assigned"});
    this.apiIsLoading = false;
    return result;
  },
  async removeClientForProduct(clientId, productId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.delete(
      `${apiUrl}/auth/admin/products/${productId}/clients/${clientId}/client.json`
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Product Un-Assigned"});
    this.apiIsLoading = false;
    return result;
  },
  async getClientsUnassignedToProduct(productId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/products/${productId}/unassigned/client.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getProductClientSettings(productId, clientId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/products/${productId}/clients/${clientId}/settings.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
};
