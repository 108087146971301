import axios from "axios";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiReact = {
  async getReactLeadList(startDate, endDate) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/react/leadlist.json?startDate=${startDate}&endDate=${endDate}`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getReactLeadListClientDomains(clientId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/react/leadlist/clients/${clientId}/domains.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getReactRuleActivations(ruleId, startDate, endDate) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/react/rules/${ruleId}/activations.json?startDate=${startDate}&endDate=${endDate}`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getReactRulePerformance(ruleId, startDate, endDate) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/react/rules/${ruleId}/performance.json?startDate=${startDate}&endDate=${endDate}`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getReactAdGroupPerformance(adgroupId, startDate, endDate) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/react/adgroup/${adgroupId}/performance.json?startDate=${startDate}&endDate=${endDate}`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  }
};
