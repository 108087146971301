import axios from "axios";
import {EventBus} from "@/event";
export const apiUrl = import.meta.env.VITE_API_URL;
export const apiUsers = {

  async getPropertyUsers(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/${propertyId}/users/user.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async assignPropertyToUser(propertyId, userId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/properties/${propertyId}/users/${userId}/user.json`
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "User assigned to property"});
    this.apiIsLoading = false;
    return result;
  },
  async removeUserForProperty(propertyId, userId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.delete(
      `${apiUrl}/auth/admin/properties/${propertyId}/users/${userId}/user.json`
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "User Un-Assigned"});
    this.apiIsLoading = false;
    return result;
  },
  async getUsersUnassignedToProperty(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/${propertyId}/users/unassigned/user.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },

}
