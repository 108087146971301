import axios from "axios";
import {EventBus} from "@/event";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiEmailHistory = {
  async getEmailHistory(search) {
    this.apiIsLoading = true;
    let result;
    const searchQuery = search ? `?search=${search}` : "";
    result = await axios.get(
      `${apiUrl}/auth/admin/email/history/email.json${searchQuery}`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
};
