import axios from "axios";
import {EventBus} from "@/event";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiCron = {
  async getCronJobs() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/admin/cron/jobs/job.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getCronJobById(jobId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/cron/jobs/${jobId}/job.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getCronHistory() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/cron/history/history.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async resetCron(payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/cron/reset/cron.json`,
      payload
    );
    EventBus.$emit("snackbar", {text: "Success"});
    this.apiIsLoading = false;
    result = result.data;
    return result;
  },
  async updateCronEnabled(id, payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/admin/cron/${id}/enabled/cron.json`,
      payload
    );
    EventBus.$emit("snackbar", {text: "Success"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateCron(id, payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/admin/cron/${id}/cron.json`,
      payload
    );
    EventBus.$emit("snackbar", {text: "Success"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async addCron(payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(`${apiUrl}/auth/admin/cron/cron.json`, payload);
    EventBus.$emit("snackbar", {text: "Success"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
};
