//Todo error handler should have built-in retry for 400, 401, 503 and axios timeout
//Todo axios should have a timeout

import axios from "axios";
import { fetchAuthSession, fetchUserAttributes, updateUserAttributes } from 'aws-amplify/auth';
import { EventBus } from "@/event";
import { mapState } from 'pinia';
import { useAppStore } from "@/stores/app";

import { apiAdmin } from "./api/admin/index";
import { apiPartnerPortal } from "./api/partnerPortal/index";

const apiUrl = import.meta.env.VITE_API_URL;

const initAxios = (axiosInstance = axios) => {
  if (axiosInstance.interceptors.request.handlers.length === 0) {
    axiosInstance.interceptors.request.use(requestInterceptor)
    axiosInstance.interceptors.response.use(
      responseInterceptor,
      responseErrorInterceptor
    );
  }
};

const requestInterceptor = async request => {
  const authorization = await getAccessToken();
  request.headers["Authorization"] = "Bearer " + authorization;
  const store = useAppStore();
  const appState = JSON.stringify({
    impersonatedUser: store?.state?.impersonatedUser
  });
  request.headers["x-revvim-app"] = btoa(appState);
  return request;
};
const responseInterceptor = response => {
  try {
    const store = useAppStore();
    const appStateHeader = response?.headers?.['x-revvim-app'];
    if (appStateHeader) {
      const appState = JSON.parse(atob(appStateHeader));
   //   console.log('effective permissions',appState.userAuthenticated.permissions.sort((a, b) => (a.toLowerCase() < b.toLowerCase()) ? -1 : 1))
      store.setServerAppState(appState);
    }
  } catch (e) {
    throw new Error("Invalid App State;" + e);
  }
  return response;
};
const responseErrorInterceptor = error => {
  console.log("response error", error);
  return Promise.reject(error);
};

initAxios();

const getAccessToken = async () => {
  const session = await fetchAuthSession();
  if (session) {
    return session.tokens?.idToken?.toString()
  } else {
    throw new Error("Unauthenticated user");
  }
};

export const api = {
  data() {
    return {
      apiIsLoading: false,
      backend: {
        SIGN_IN: "/auth/remote/manage/signin",
        NEW_USER: "/auth/remote/manage/newuser",
        NEW_PROPERTY: "/auth/remote/manage/newproperty",
        ADD_USER: "/auth/remote/manage/adduser",
        ADD_CONN: "/auth/remote/manage/addconn",
        GA_PROFILE_LIST: "/auth/remote/manage/profilelist",
        GA_TOKEN_PROFILE_LIST: "/auth/remote/manage/tokenprofilelist",
        GSC_SITE_LIST: "/auth/remote/manage/usersitelist",
        GSC_TOKEN_SITE_LIST: "/auth/remote/manage/tokensitelist",
        SET_GA_PROFILE: "/auth/remote/manage/resetgaprofile",
        SET_GSC_SITE: "/auth/remote/manage/setgscsite",
        SET_PLAN: "/auth/remote/manage/setplan",
        GET_RECENT_DATA: "/auth/remote/manage/recent/changes",
        GET_RECENT_KW_DETAILS: "/auth/remote/manage/recent/keywords",
        GET_ELEMENT_DETAILS: "/auth/remote/manage/recent/elements",
        GET_VIEWS: "/auth/remote/manage/property/views",
        SAVE_VIEW: "/auth/remote/manage/property/upsert-view",
        DELETE_VIEW: "/auth/remote/manage/property/delete-view",
        SUBSCRIBE: "/auth/remote/manage/property/subscribe-view",
        UNSUBSCRIBE: "/auth/remote/manage/property/unsubscribe-view",
        GET_IMPACT_DATA: "/auth/remote/manage/impact/table",
        GET_IMPACT_KW_DETAILS: "/auth/remote/manage/impact/keywords",
        GET_PAGE_DETAILS: "/auth/remote/manage/detail/table",
        GET_MINI_CHARTS: "/auth/remote/manage/impact/minicharts",
        DELETE_PROPERTY: "/auth/remote/manage/deleteproperty",
        USER_SITE_CLICKS: "/auth/remote/manage/usersiteclicks",
        TOKEN_SITE_CLICKS: "/auth/remote/manage/tokensiteclicks",

        ADD_USER_BY_ID: "/auth/remote/manage/add-user",
        GET_RELATED_USERS: "/auth/remote/manage/get-related-users",
        REMOVE_USER: "/auth/remote/manage/remove-user",
        CHANGE_OWNER: "/auth/remote/manage/changeowner",
        SET_ADAI_INTEREST: "/auth/remote/manage/setadaiinterest",

        ADAI_CHANGE_LOG: "/auth/remote/manage/adai/changelog",
        ADAI_IMPACT: "/auth/remote/manage/adai/impact",
        ADAI_RECLAIMED: "/auth/remote/manage/adai/reclaimed",
        ADAI_CONQUESTED: "/auth/remote/manage/conquested",
        ADAI_COMPETITORS: "/auth/remote/manage/adai/competitorsv2",
        ADAI_SEARCH_TERM_SETS: "/auth/remote/manage/adai/searchtermsets",

        REACT_RULES: "/auth/remote/manage/react/rules",
        REACT_CAMPAIGNS: "/auth/remote/manage/react/campaigns",
        REACT_CAMPAIGN_ADGROUPS: "/auth/remote/manage/react/campaignadgroups",
        REACT_CAMPAIGN_ADGROUP_ADS: "/auth/remote/manage/react/campaignadgroupads",
        REACT_CREATE_RULE: "/auth/remote/manage/react/rule/insert",
        REACT_DELETE_RULE: "/auth/remote/manage/react/rule/delete",

        // Update rule
        REACT_UPDATE_NAME: "/auth/remote/manage/react/rule/update/name",
        REACT_UPDATE_STATUS: "/auth/remote/manage/react/rule/update/status",
        REACT_UPDATE_CONFIG: "/auth/remote/manage/react/rule/update/config",

        // Update ads
        REACT_INSERT_AD: "/auth/remote/manage/react/rule/insert/ad",
        REACT_DELETE_AD: "/auth/remote/manage/react/rule/delete/ad",

      }
    };
  },
  computed: {
    ...mapState(useAppStore, ["user", "cognito"])
  },
  methods: {
    ...apiAdmin,
    ...apiPartnerPortal,

    // timezone helper functions for use in scheduled actions or anything needing to run in PDT not UTC
    fromUTCToPacific(dateTime) {
      return new Date(`${dateTime.replace("Z", "-07:00")}`);
    },
    toUTCFromPacific(dateTime) {
      return new Date(`${dateTime.replace("Z", "+07:00")}`);
    },
    toTimeFromDate(dateTime) {
      return dateTime
        ? `${new Date(dateTime).getHours()}:${new Date(dateTime).getMinutes()}`
        : "00:00";
    },
    prepareTime(time) {
      let hours = time.split(":")[0];
      let minutes = time.split(":")[1];
      if (minutes.length == 1) {
        minutes = `0${minutes}`;
      }
      if (hours.length == 1) {
        hours = `0${hours}`;
      }
      return `${hours}:${minutes}`;
    },
    // end timezone helper functions

    // general permissions
    hasPermission: function(permission) {
      return this.$store.hasPermission(permission);
    },

    //help method for determining matching access
    hasMatching(source, toMatch) {
      if (toMatch === undefined ) {
        return true
      }
      if (typeof toMatch === "string") {
        return (
            source &&
            source.includes(toMatch)

        );
      } else {
        let has = false;
        toMatch.map(p => {
          if (
              source &&
              source.includes(p)
          ) {
            has = true;
          }
        });
        return has;
      }
    },

    getContext(methodName = "") {
      let context = "";
      if (this.$options) {
        if (this.$options.parent && this.$options.parent.$options) {
          context += this.$options.parent.$options.name + " > ";
        }
        if (this.$options.name) {
          context += this.$options.name;
        }
      }
      return context + " > " + methodName;
    },

    async handleClientError(clientError, context = "", userNotification = false) {
      if (userNotification) {
        const snackbarSettings = {
          timeout: -1,
          color: 'red-lighten-1',
          text: this.$t('general.generalError'),
          ...userNotification
        }
        EventBus.$emit('snackbar', snackbarSettings);
      }

      await this.reportClientError(clientError, context);
    },

    async reportClientError(clientError, context) {
      let data = {
        clientError,
        context,
        location: location.href
      };

      if (this.$store.user.userId) {
        data.userId = this.$store.user.userId;
      }

      try {
        await axios.post(`${apiUrl}/auth/client-error`, data);
      } catch (err) {
        //Error reporting the error
        console.error(err);
      }
    },

    async backendApiCall(path, data = {}, method = 'post') {
      //Send the userId in for impersonation purposes
      //Will be ignored by the middleware if user is not
      //in revvim-admin group
      if (this.$store.user.userId) {
        data.userId = this.$store.user.userId;
      }

      // Adding a property always uses the 'onboarding user' until we get the onboards done.
      if(path === '/auth/remote/manage/usersitelist'){
        data.userId = 100060
      }

      this.apiIsLoading = true;
      let reqConfig = {
        url: `${apiUrl}${path}`,
        timeout: 1000 * 60 * 10,
        method,
      };
      if(method === 'post'){
        reqConfig.data = data;
      }else{
        reqConfig.params = data;
      }

      let result;
      try {
        result = await axios(reqConfig);
        result = result.data;

        // Catch errors that have a 200 status
        if(typeof result === 'string' && result.includes('error')){
          throw new Error(result);
        }
      } catch (error) {
        //throwing to the component
        throw error;
      } finally {
        this.apiIsLoading = false;
      }

      return result;
    },

    async updateUserAttributes(attributes) {
      this.apiIsLoading = true;
      let success = true;
      try {
        //If we're sending attributes to cognito:
        if (attributes) {
          await updateUserAttributes({ userAttributes: attributes });
        }
        //Make sure we have the latest attributes from Cognito
        const cognitoAttributes = await fetchUserAttributes();
        //Update the attributes in the store
        this.$store.setCognitoAttributes(cognitoAttributes);
      } catch (error) {
        //throwing to the component
        throw error;
      } finally {
        this.apiIsLoading = false;
      }
      return success;
    },

    //Invite User
    async inviteDelegatedUser(email, properties) {
      this.apiIsLoading = true;
      let data = { email, properties };
      if (this.$store.user.userId) {
        data.userId = this.$store.user.userId;
      }
      try {
        let result = await axios.post(`${apiUrl}/auth/users/invite`, data);
        //console.log(result);
      } catch (error) {
        //throwing to the component
        throw error;
      } finally {
        this.apiIsLoading = false;
      }
      return true;
    },

    //Delete invited User
    async deleteUserInvite(invite) {
      this.apiIsLoading = true;
      let data = { invite };
      if (this.$store.user.userId) {
        data.userId = this.$store.user.userId;
      }
      try {
        await axios.post(`${apiUrl}/auth/users/invite-delete`, data);
      } catch (error) {
        //throwing to the component
        throw error;
      } finally {
        this.apiIsLoading = false;
      }
      return true;
    },

    //Accept Invite
    async redeemInviteToken(token) {
      this.apiIsLoading = true;
      let data = { token };
      let result = [];
      if (this.$store.user.userId) {
        data.userId = this.$store.user.userId;
      }
      try {
        result = await axios.post(`${apiUrl}/auth/users/invite-accept`, data);
      } catch (error) {
        //throwing to the component
        throw error;
      } finally {
        this.apiIsLoading = false;
      }
      return result;
    },

    async getPendingInvites() {
      this.apiIsLoading = true;
      let data = {};
      let result = [];
      if (this.$store.user.userId) {
        data.userId = this.$store.user.userId;
      }
      try {
        result = await axios.post(
          `${apiUrl}/auth/users/invite-pending-list`,
          data
        );
        result = result.data;
      } catch (error) {
        //throwing to the component
        throw error;
      } finally {
        this.apiIsLoading = false;
      }
      return result;
    },

    async getUserEmail(userId) {
      this.apiIsLoading = true;
      let result;
      try {
        result = await axios.get(`${apiUrl}/auth/users/${userId}/email.json`);
        result = result.data;
      } catch (error) {
        //throwing to the component
        throw error;
      } finally {
        this.apiIsLoading = false;
      }
      return result;
    },

    async getUserData() {
      let email;
      if (this.user.impersonatedEmail) {
        email = this.user.impersonatedEmail;
      } else {
        email = this.cognito.attributes.email;
      }

      const userData = await this.backendApiCall(this.backend.SIGN_IN, {
        email
      });

      this.$store.setUser( userData);
      return true;
    },

    async sendAdAiOnboardEmail(properties) {
      this.apiIsLoading = true;
      let data = { properties };
      if (this.$store.user.userId) {
        data.userId = this.$store.user.userId;
      }
      try {
        let result = await axios.post(
          `${apiUrl}/auth/users/adai-onboard`,
          data
        );
      } catch (error) {
        //throwing to the component
        throw error;
      } finally {
        this.apiIsLoading = false;
      }
      return true;
    },

    //GA Api Related:
    async getTokens(code) {
      this.apiIsLoading = true;
      let tokens;
      try {
        let result = await axios.post(`${apiUrl}/auth/analytics/tokens`, {
          code
        });
        tokens = result.data;
      } catch (error) {
        //throwing to the component
        throw error;
      } finally {
        this.apiIsLoading = false;
      }
      return tokens;
    },
    async gaConsent() {
      let state = await getAccessToken();
      let path = this.$route.path;
      state = btoa(JSON.stringify({ ct: state, path }));
      window.location.href = `${import.meta.env.VITE_API_URL}/analytics/ga-auth.json?state=${state}`;
    },
    async userExists(email) {
      this.apiIsLoading = true;
      let result = false;
      try {
        result = await axios.get(`${apiUrl}/pub/userExists?email=${email}`);
        result = result.data;
      } catch (error) {
        //throwing to the component
        throw error;
      } finally {
        this.apiIsLoading = false;
      }
      return result;
    },

    //Cognito Data Update Utility
    async updateCognitoData(data) {
      this.apiIsLoading = true;
      try {
        await axios.put(`${apiUrl}/auth/users/user.json`, data);
      } catch (error) {
        //throwing to the component
        throw error;
      } finally {
        this.apiIsLoading = false;
      }
      return true;
    }
  }
};
