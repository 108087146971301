import axios from "axios";
import {EventBus} from "@/event";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiDomains = {
  async getDomains(search) {
    this.apiIsLoading = true;
    let result;
    const searchQuery = search ? `?search=${search}` : "";
    result = await axios.get(
      `${apiUrl}/auth/admin/domains/domains.json${searchQuery}`
    );
    // EventBus.$emit("snackbar", { text: "Test Sent" });
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getDomainById(domainId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/domains/${domainId}/domain.json`
    );
    // EventBus.$emit("snackbar", { text: "Test Sent" });
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateDomainById(domainId, domain) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/admin/domains/${domainId}/domain.json`,
      domain
    );
    EventBus.$emit("snackbar", {text: "Domain Updated"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  }
};
