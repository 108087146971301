import axios from "axios";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiReports = {
  async getReports(category) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/reports/${category}/reports.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },

  async getReportById(report, reportKeyId, fromDate, toDate) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/reports/${report.id}/key/${reportKeyId}/report.json?fromDate=${fromDate}&toDate=${toDate}`,
      {
        responseType: report.format === "excel" ? "blob" : "application/json"
      }
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },

  async getPropertySummary(propertyId, fromDate, toDate) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/reports/properties/${propertyId}/summary/report.json?fromDate=${fromDate}&toDate=${toDate}`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },

  async getPropertyPassiveData(propertyId, fromDate, toDate) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/reports/properties/${propertyId}/passiveData/report.json?fromDate=${fromDate}&toDate=${toDate}`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  }
};
