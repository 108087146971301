import axios from "axios";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiOrganizations = {
  async getPipeDriveOrganizations() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/pipedrive/organizations/organizations.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPipeDriveOrganizationById(orgId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/pipedrive/organizations/${orgId}/organization.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPipeDriveOrganizationActivitiesById(orgId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/pipedrive/organizations/${orgId}/activities.json`
    );
    result = result.data;
    this.apiIsLoading = false;

    return result;
  },
  async getPipeDriveOrganizationRelationshipsById(orgId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/pipedrive/organizations/${orgId}/organizationRelationships.json`
    );
    result = result.data;
    this.apiIsLoading = false;

    return result;
  },
  async getPipeDriveOrganizationPeopleById(orgId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/pipedrive/organizations/${orgId}/people.json`
    );
    result = result.data;
    this.apiIsLoading = false;

    return result;
  },
  async getPipeDriveOrganizationDealsById(orgId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/pipedrive/organizations/${orgId}/deals.json`
    );
    result = result.data;
    this.apiIsLoading = false;

    return result;
  },
  async getPipeDriveOrganizationLeadsById(orgId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/pipedrive/organizations/${orgId}/leads.json`
    );
    result = result.data;
    this.apiIsLoading = false;

    return result;
  },
  async getPipeDriveOrganizationNotesById(orgId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/pipedrive/organizations/${orgId}/notes.json`
    );
    result = result.data;
    this.apiIsLoading = false;

    return result;
  },
  async getPipeDriveOrganizationFlowById(orgId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/pipedrive/organizations/${orgId}/flow.json`
    );
    result = result.data;
    this.apiIsLoading = false;

    return result;
  }
}
