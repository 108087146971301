import prodNames from "@/util/prodNames";

export const CustomerSuccess = [
  {
    path: "/app/cs/customers/:clientId/view/:view",
    name: "app.cs.customer.detail.view",
    meta: {
      requiresAuth: true,
      department: 'cs',
      prodName: prodNames.REVVIM,
      permission: ["admin-client", "admin-client-read"],
      breadCrumb(route) {
        const clientId = route?.params?.clientId;
        const view = route?.params?.view;
        return [
          {
            text: "Home",
            to: {
              name: "app-home",
            },
          },
          {
            text: "Customer Success",
            to: {
              name: "app.cs",
            },
          },
          {
            text: "Customers",
            to: {
              name: "app.cs.customer",
            },
          },
          {
            text: clientId,
            to: {
              name: "app.cs.customer.detail",
            }
          },
          {
            text: view,
            to: {
              name: "app.cs.customer.detail.view",
            },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-clients" */ "@/views/accounting/clients/ClientDetailTabs.vue"
        ),
  },
  {
    path: "/app/cs/customers/:clientId",
    name: "app.cs.customer.detail",
    meta: {
      requiresAuth: true,
      department: 'cs',
      prodName: prodNames.REVVIM,
      permission: ["admin-unreleased","admin-cs", "admin-cs-read", "admin-client-read"],
      breadCrumb(route) {
        const clientId = route.params.clientId;
        return [
          {
            text: "Home",
            to: {
              name: "app-home",
            },
          },
          {
            text: "Customer Success",
            to: {
              name: "app.cs",
            },
          },
          {
            text: "Customers",
            to: { name: "app.cs.customer" },
          },
          {
            text: clientId,
            to: {
              name: "app.cs.customer.detail",
            },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-clients" */ "@/views/accounting/clients/ClientDetailTabs.vue"
        ),
  },
  {
    path: "/app/cs/customers",
    name: "app.cs.customer",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'cs',
      permission: ["admin-client", "admin-client-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Customer Success",
            to: { name: "app.cs" },
          },
          {
            text: "Customers",
            to: { name: "app.cs.customer" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-clients" */ "@/views/accounting/clients/ClientsView"
        ),
  },
  {
    path: "/app/cs",
    name: "app.cs",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'cs',
      permission: ["admin-unreleased", "admin-cs", "admin-cs-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: {
              name: "app-home",
            },
          },

          {
            text: "Customer Success",
            to: {
              name: "app.cs",
            },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-agents" */ "@/views/cs/CSDashboard.vue"
        ),
  },
];
