import prodNames from "@/util/prodNames";

export const Deal = [
  {
    path: "/app/sales/deals/:dealId",
    name: "app.sales.deal.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'sales',
      permission: ["admin-deal", "admin-deal-read"],
      breadCrumb(route) {
        const dealId = route.params.dealId;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Sales",
            to: { name: "app.sales" },
          },
          {
            text: "Deals",
            to: { name: "app.sales.deal" },
          },
          {
            text: dealId,
            to: { name: "app.sales.deal.detail" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-deals-detail" */ "@/views/sales/deals/DealDetailTabs"
      ),
  },
  {
    path: "/app/sales/deals",
    name: "app.sales.deal",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'sales',
      permission: ["admin-deal", "admin-deal-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Sales",
            to: { name: "app.sales" },
          },
          {
            text: "Deals",
            to: { name: "app.sales.deal" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-deals" */ "@/views/sales/deals/DealsView"
      ),
  },
];
