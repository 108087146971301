import prodNames from "@/util/prodNames";

export const Cron = [
  {
    path: "/app/admin/cron/jobs/:jobId",
    name: "app.admin.cron.jobs.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-cron", "admin-cron-read"],
      breadCrumb(route) {
        const jobId = route.params.jobId;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Admin",
            to: { name: "app.admin" },
          },
          {
            text: "Cron Jobs",
            to: { name: "app.admin.cron.jobs" },
          },
          {
            text: jobId,
            to: { name: "app.admin.cron.jobs.detail" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-cron-detail" */ "@/views/admin/cron/CronDetail.vue"
      ),
  },
  {
    path: "/app/admin/cron/jobs",
    name: "app.admin.cron.jobs",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-cron", "admin-cron-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Admin",
            to: { name: "app.admin" },
          },
          {
            text: "Cron Jobs",
            to: { name: "app.admin.cron.jobs" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-cronJobs" */ "@/views/admin/cron/CronView.vue"
      ),
  },
  {
    path: "/app/admin/cron/history",
    name: "app.admin.cron.history",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-cron", "admin-cron-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Admin",
            to: { name: "app.admin" },
          },
          {
            text: "Cron History",
            to: { name: "app.admin.cron.history" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-cronJobs" */ "@/views/admin/cron/CronHistoryView.vue"
      ),
  },
];
