<template>
  <v-footer class="footer">
    Revvim Console  &copy; {{ new Date().getFullYear() }}
    <v-spacer></v-spacer>
    <v-btn
      variant="text"
      class="no-text-transform"
      href="https://revvim.com/terms-of-service"
      color="grey"
      target="_blank"
    >
        Terms of Service
    </v-btn>

    <v-btn
      variant="text"
      class="no-text-transform"
      href="https://revvim.com/privacy-policy"
      color="grey"
      target="_blank"
    >
        Privacy Policy
    </v-btn>

  </v-footer>
</template>

<style scoped>
  .footer {
    font-size: 8pt;
    flex: 0 1 auto !important;
    color: white;
    background-color: #202020;
  }
</style>
