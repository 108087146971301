import axios from "axios";
import {EventBus} from "@/event";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiProductPropertyGroups = {
  async createProductPropertyGroup(productPropertyGroup) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(`${apiUrl}/auth/products/propertyGroups/productPropertyGroup.json`, productPropertyGroup);
    EventBus.$emit("snackbar", {text: "Production Group Created"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateProductPropertyGroup(productPropertyGroupId, productPropertyGroup) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/products/propertyGroups/${productPropertyGroupId}/productPropertyGroup.json`,
      productPropertyGroup
    );
    EventBus.$emit("snackbar", {text: "ProductPropertyGroup Updated"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async deleteProductPropertyGroupById(productPropertyGroupId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.delete(
      `${apiUrl}/auth/products/propertyGroups/${productPropertyGroupId}/productPropertyGroup.json`
    );
    EventBus.$emit("snackbar", {text: "ProductPropertyGroup Deleted"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getProductPropertyGroups(productId, clientId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/products/propertyGroups/product/${productId}/client/${clientId}/productPropertyGroups.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getProductPropertyGroupProperties(productPropertyGroupId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/products/propertyGroups/${productPropertyGroupId}/properties.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },

  async reassignPropertyToProductPropertyGroup(reassignment) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/products/propertyGroups/reassign/productPropertyGroup.json`,
      reassignment
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Property Assigned"});
    this.apiIsLoading = false;
    return result;
  },


  async assignPropertyToProductPropertyGroup(productPropertyGroupId, propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/products/propertyGroups/${productPropertyGroupId}/property/productPropertyGroup.json`,
      {PropertyId: propertyId}
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Property Assigned"});
    this.apiIsLoading = false;
    return result;
  },
  async removePropertyFromProductPropertyGroup(productPropertyGroupId, propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.delete(
      `${apiUrl}/auth/products/propertyGroups/${productPropertyGroupId}/property/${propertyId}/productPropertyGroup.json`
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Client Un-Assigned"});
    this.apiIsLoading = false;
    return result;
  },

  async getPropertiesUnassignedToProductPropertyGroupProduct(clientId, productId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/products/propertyGroups/client/${clientId}/product/${productId}/unassigned/properties.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },

  async getClientProductionForProductPropertyGroupPeriod(clientId, yearMonth) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/products/propertyGroups/client/${clientId}/period/${yearMonth}/production.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getClientProductionForProductPropertyGroupPeriodProperties(groupId, yearMonth) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/products/propertyGroups/${groupId}/period/${yearMonth}/production.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  }
};
