import axios from "axios";
import {EventBus} from "@/event";

const apiUrl = import.meta.env.VITE_API_URL;

export const apiOrganizations = {

  // Organizations
  async getOrganizations() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/organizations/organizations.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getOrganizationById(organizationId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/organizations/${organizationId}/organization.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getOrganizationRelationshipTypes() {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(`${apiUrl}/auth/organizations/relationships/relationship.json`);
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getOrganizationRelatedByType(organizationId, typeId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/organizations/${organizationId}/related/${typeId}/organization.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getAvailableOrganizationsToOrganizationByRelationshipType(organizationId, typeId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/organizations/${organizationId}/related/${typeId}/available/organization.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updateOrganization(organizationId, payload) {
    this.apiIsLoading = true;
    let result;
    try {
      result = await axios.put(
        `${apiUrl}/auth/organizations/${organizationId}/organization.json`,
        payload
      );
      result = result.data;
      this.apiIsLoading = false;
      EventBus.$emit("snackbar", {text: "Success"});
      return result;
    } catch (e) {
      this.apiIsLoading = false;
      EventBus.$emit("snackbar", {color:"error", text: "Save Failed"});
    }
  },
  async addOrganization(payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(`${apiUrl}/auth/organizations/organization.json`, payload);
    EventBus.$emit("snackbar", {text: "Success"});
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getOrganizationUsersById(organizationId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/organizations/${organizationId}/users/user.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async assignUserToOrganization(organizationId, payload){
    this.apiIsLoading = true;
    let result;
    try {
      result = await axios.post(
        `${apiUrl}/auth/organizations/${organizationId}/users/user.json`,
        payload
      );
      result = result.data;
      this.apiIsLoading = false;

      return result;
    } catch (e) {
      this.apiIsLoading = false;
      EventBus.$emit("snackbar", {color:"error", text: "Save Failed"});
    }
  },
  async removeUserForOrganization(organizationId, payload){
    this.apiIsLoading = true;
    let result;
    try {
      result = await axios.post(
        `${apiUrl}/auth/organizations/${organizationId}/users/remove/user.json`,
        payload
      );
      result = result.data;
      this.apiIsLoading = false;
      EventBus.$emit("snackbar", {text: "Success"});
      return result;
    } catch (e) {
      this.apiIsLoading = false;
      EventBus.$emit("snackbar", {color:"error", text: "Save Failed"});
    }
  },
  async getOrganizationMccOpportunitiesByOrgId(organizationId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/organizations/${organizationId}/mcc/opportunities/opportunities.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getOrganizationPropertiesByOrgId(organizationId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/organizations/${organizationId}/properties/properties.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },

};
