import prodNames from "@/util/prodNames";

export const Report = [
  {
    path: "/app/reports",
    name: "app.report",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: [
        "admin-report-property",
        "admin-report-deal",
        "admin-report-client",
      ],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Reports",
            to: { name: "app.report" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-reports" */ "@/views/reports/ReportsView"
      ),
  },
];
