import prodNames from "@/util/prodNames";

const metaProperties = {
    requiresAuth: true,
    permission: "react",
    prodName: prodNames.REACT,
}
export const React = [
    {
        path: "/react",
        redirect: { name: "app.react.properties" }
    },
    {
        path: "/app/react",
        name: "app.react",
        meta: {...metaProperties},
        redirect: { name: "app.react.properties" },
        props(route) {
            const props = { ...route.params };
            props.propertyId = +props.propertyId || null;
            props.adGroupId = +props.adGroupId || null;
            return props;
        },
        component: () => import(/* webpackChunkName: "React-Main" */ "@/views/react/Main.vue"),
        children: [
            {
                path: "/app/react/properties",
                name: "app.react.properties",
                meta: {...metaProperties},
                component: () => import(/* webpackChunkName: "React-Properties" */ "@/views/react/Properties.vue"),
            },
            {
                path: "/app/react/property/:propertyId",
                name: "app.react.property",
                meta: {
                    ...metaProperties,
                    permission: "react-viewProperty"
                },
                component: () => import(/* webpackChunkName: "React-PropertyRules" */ "@/views/react/PropertyRules.vue")
            },
            {
                path: "/app/react/property/:propertyId/adgroup/:adGroupId",
                name: "app.react.adGroupDetails",
                meta: {
                    ...metaProperties,
                    permission: "react-viewProperty"
                },
                component: () => import(/* webpackChunkName: "React-AdGroupDetails" */ "@/views/react/AdGroupDetails.vue")
            },
        ]
    },

]