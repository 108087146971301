const amplifyConfig = {
    Auth:{
        Cognito: {
            allowGuestAccess: false,
            userPoolId: import.meta.env.VITE_COG_UPOOL_ID,
            userPoolClientId: import.meta.env.VITE_COG_CLIENT_ID,
            loginWith: {
              email: true,
              oauth: {
                domain: import.meta.env.VITE_COG_DOMAIN,
                scopes: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
                redirectSignIn: [import.meta.env.VITE_BASE_URL + import.meta.env.VITE_LOGIN_CB],
                redirectSignOut: [import.meta.env.VITE_BASE_URL + import.meta.env.VITE_LOGOUT_CB],
                responseType: 'code',
              }
            },
            signUpVerificationMethod: "code",
            userAttributes: {
              email: {
                required: true,
              },
            },
            passwordFormat: {
              minLength: 8,
              requireLowercase: true,
              requireUppercase: true,
              requireNumbers: true,
              requireSpecialCharacters: true,
            },
        }
    }
}

const loginFormConfig = {
    loginMechanisms: ['email'],
    socialProviders: ['google'],
    formFields: {
        signUp: {
            name:{
              label: 'Name:',
              placeholder: 'Enter your name',
              isRequired: true,
              order: 1,
            },
            company: {
              label: 'Company:',
              placeholder: 'Enter your company',
              isRequired: false,
              order: 2,
            },
            email: {
              label: 'Email:',
              placeholder: 'Enter your email',
              isRequired: true,
              order: 3,
            },
            password: {
              label: 'Password:',
              placeholder: 'Enter your password',
              isRequired: true,
              order: 5,
            },
            confirm_password: {
              label: 'Confirm password:',
              order: 6,
            },
        },
    }    
}

export { amplifyConfig, loginFormConfig };