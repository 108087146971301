import prodNames from "@/util/prodNames";

export const Client = [
  {
    path: "/app/accounting/customer-payments/:paymentId",
    name: "app.accounting.customer-payment.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'accounting',
      permission: ["admin-client", "admin-client-read"],
      breadCrumb(route) {
        const paymentId = route.params.paymentId;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Accounting",
            to: { name: "app.accounting" },
          },
          {
            text: "Customer Payments",
            to: { name: "app.accounting.customer-payment" },
          },
          {
            text: paymentId,
            to: { name: "app.accounting.customer-payment.detail" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-clientPaymentsDetail" */ "@/views/accounting/clients/payments/PaymentsViewDetailTabs.vue"
      ),
  },
  {
    path: "/app/accounting/customer-payments",
    name: "app.accounting.customer-payment",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'accounting',
      permission: ["admin-client", "admin-client-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Accounting",
            to: { name: "app.accounting" },
          },
          {
            text: "Customer Payments",
            to: { name: "app.accounting.customer-payment" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-clientPayments" */ "@/views/accounting/clients/payments/PaymentsView"
      ),
  },
  {
    path: "/app/accounting/customers/:clientId/view/:view",
    name: "app.accounting.customer.detail.view",
    meta: {
      requiresAuth: true,
      department: 'accounting',
      prodName: prodNames.REVVIM,
      permission: ["admin-client", "admin-client-read"],
      breadCrumb(route) {
        const clientId = route?.params?.clientId;
        const view = route?.params?.view;
        return [
          {
            text: "Home",
            to: {
              name: "app-home",
            },
          },
          {
            text: "Accounting",
            to: {
              name: "app.accounting",
            },
          },
          {
            text: "Customers",
            to: {
              name: "app.accounting.customer",
            },
          },
          {
            text: clientId,
            to: {
              name: "app.accounting.customer.detail",
            }
          },
          {
            text: view,
            to: {
              name: "app.accounting.customer.detail.view",
            },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-clients" */ "@/views/accounting/clients/ClientDetailTabs.vue"
        ),
  },
  {
    path: "/app/accounting/customers/:clientId",
    name: "app.accounting.customer.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'accounting',
      permission: ["admin-client", "admin-client-read"],
      breadCrumb(route) {
        const clientId = route.params.clientId;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Accounting",
            to: { name: "app.accounting" },
          },
          {
            text: "Customers",
            to: { name: "app.accounting.customer" },
          },
          {
            text: clientId,
            to: { name: "app.accounting.customer.detail" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-clients" */ "@/views/accounting/clients/ClientDetailTabs.vue"
      ),
  },
  {
    path: "/app/accounting/customers",
    name: "app.accounting.customer",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'accounting',
      permission: ["admin-client", "admin-client-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Accounting",
            to: { name: "app.accounting" },
          },
          {
            text: "Customers",
            to: { name: "app.accounting.customer" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-clients" */ "@/views/accounting/clients/ClientsView"
      ),
  }
];
