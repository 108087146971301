import prodNames from "@/util/prodNames";
import moment from "moment/moment";

export const Production = [
  {
    path: "/app/accounting/production/workorders/period/:yearMonth/workorder/:workOrderId",
    name: "app.accounting.production.workorder.period.workOrder.detail",
    meta: {
      requiresAuth: true,
      department: 'accounting',
      prodName: prodNames.REVVIM,
      permission: ["admin-production", "admin-production-read"],
      breadCrumb(route) {
        const yearMonth = route.params.yearMonth;
        const workOrderId = route.params.workOrderId;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Accounting",
            to: { name: "app.accounting" },
          },
          {
            text: "Production",
            to: { name: "app.accounting.production" },
          },
          {
            text: "Work Orders",
            to: { name: "app.accounting.production" },
          },
          {
            text: moment(yearMonth).format("MMM YYYY"),
            to: { name: "app.accounting.production.workorder.period" },
          },
          {
            text: "Work Order",
            to: { name: "app.accounting.production.workorder.period" },
          },
          {
            text: workOrderId,
            to: {
              name: "app.accounting.production.workorders.period.workOrder.detail",
            },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-production" */ "@/views/accounting/workOrders/WorkOrdersItemsTable"
      ),
  },
  {
    path: "/app/accounting/production/workorders/period/:yearMonth",
    name: "app.accounting.production.workorder.period",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'accounting',
      permission: ["admin-production", "admin-production-read"],
      breadCrumb(route) {
        const yearMonth = route.params.yearMonth;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Accounting",
            to: { name: "app.accounting" },
          },
          {
            text: "Production",
            to: { name: "app.accounting.production" },
          },
          {
            text: "Work Orders",
            to: { name: "app.accounting.production" },
          },
          {
            text: moment(yearMonth).format("MMM YYYY"),
            to: { name: "app.accounting.production.workorder.period" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-production" */ "@/views/accounting/workOrders/WorkOrdersByPeriodTable"
      ),
  },
  {
    path: "/app/accounting/production/adai/period/:yearMonth",
    name: "app.accounting.production.adai.period",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'accounting',
      permission: ["admin-production", "admin-production-read"],
      breadCrumb(route) {
        const yearMonth = route.params.yearMonth;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Accounting",
            to: { name: "app.accounting" },
          },
          {
            text: "Production",
            to: { name: "app.accounting.production" },
          },
          {
            text: "Ad Ai",
            to: { name: "app.accounting.production" },
          },
          {
            text: moment(yearMonth).format("MMM YYYY"),
            to: { name: "app.accounting.production.adai.period" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-production" */ "@/views/accounting/production/ProductionProductTabs"
      ),
  },
  {
    path: "/app/accounting/production/fraudai/period/:yearMonth",
    name: "app.accounting.production.fraudai.period",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'accounting',
      permission: ["admin-production", "admin-production-read"],
      breadCrumb(route) {
        const yearMonth = route.params.yearMonth;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Accounting",
            to: { name: "app.accounting" },
          },
          {
            text: "Production",
            to: { name: "app.accounting.production" },
          },
          {
            text: "Fraud Ai",
            to: { name: "app.accounting.production" },
          },
          {
            text: moment(yearMonth).format("MMM YYYY"),
            to: { name: "app.accounting.production.fraudai.period" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-production" */ "@/views/accounting/production/ProductionProductTabs"
      ),
  },
  {
    path: "/app/accounting/production/react/period/:yearMonth",
    name: "app.accounting.production.react.period",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'accounting',
      permission: ["admin-production", "admin-production-read"],
      breadCrumb(route) {
        const yearMonth = route.params.yearMonth;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Accounting",
            to: { name: "app.accounting" },
          },
          {
            text: "Production",
            to: { name: "app.accounting.production" },
          },
          {
            text: "React",
            to: { name: "app.accounting.production" },
          },
          {
            text: moment(yearMonth).format("MMM YYYY"),
            to: { name: "app.accounting.production.react.period" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-production" */ "@/views/accounting/production/ProductionProductTabs"
      ),
  },
  {
    path: "/app/accounting/production",
    name: "app.accounting.production",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'accounting',
      permission: ["admin-production", "admin-production-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Accounting",
            to: { name: "app.accounting" },
          },
          {
            text: "Production",
            to: { name: "app.accounting.production" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-production" */ "@/views/accounting/production/ProductionProductTabs"
      ),
  },
];
