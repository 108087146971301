export const PartnerPortal = [
    {
        path: "/app/partnerPortal/:tab?",
        name: "app.partnerPortal",
        meta: {
            requiresAuth: true,
            permission: ["partnerPortal", "Partner"]
        },
        props(route) {
            const props = { ...route.params };
            props.tab = +props.tab;
            return props;
        },
        component: () => import(/* webpackChunkName: "PartnerPortal" */  "@/views/partnerPortal/PartnerPortal.vue")
    },
    {
        path: "/app/partnerPortal/commissions/:tab?",
        name: "app.partnerPortalCommissions",
        meta: {
            permission: ["partnerPortal", "Partner"]
        },
        props(route) {
            const props = { ...route.params };
            props.tab = +props.tab;
            return props;
        },
        component: () => import(/* webpackChunkName: "PartnerPortal-Commissions" */ "@/views/partnerPortal/PartnerPortal.vue")
    },
]
