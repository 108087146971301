let helpContent = {
    adai: {}
};

helpContent.adai.impactChartTitle = 'AdAi Impact Chart Help';
helpContent.adai.impactChartContent = `
<h3 class="title mb-2">What does this report show me?</h3>
<p>This report show you how AdAi was able to shift traffic from your ad clicks to your organic clicks without a negative impact to your Blended Click Through Rate (BCTR).   Keep in mind that the data is always 2-3 days behind because of the limitation of Google’s Search Console API.</p>

<h3 class="title mb-2">Report Definitions</h3>

<h4>Blended CTR</h4>
<p>The Blended Click Through Rate shows the percentage of searches on this set of <a rel="noopener noreferrer" target="_blank" href="https://support.google.com/google-ads/answer/2472708">search terms</a> that resulted in a click on either your paid ad or organic listing.  You can hover over Blended CTR to see it highlighted or click it to hide it.</p>

<h4>Ad Cost</h4>
<p>Ad Cost is the total cost of the ads on Google for the date range specified for the search terms that were uncontested during that date range.
</p>

<h4>Organic Clicks</h4>
<p>The Organic Clicks displayed here are the total free clicks for this set of search terms as taken from your Google Search Console.</p>

<h4>Ad Clicks</h4>
<p>The Ad Clicks displayed here are the total ad clicks for this set of search terms as taken from your Google Ads account.</p>
<p>Note: Because Organic Clicks and Ad Clicks are displayed in a stacked column, it is often visually helpful to hide one of them to see them in absolute and not relative terms by clicking on them in the key provided.</p>
`;

helpContent.adai.reclaimedChartTitle = 'AdAi Reclaimed Chart Help';
helpContent.adai.reclaimedChartContent = `
<h3 class="title mb-2">What does this report show me?</h3>
<p>This report shows you by day the money that was spent and saved on the specific set of <a rel="noopener noreferrer" target="_blank" href="https://support.google.com/google-ads/answer/2472708">search terms</a> being actioned by AdAi on that specific day.  It also shows the fee for the date range selected.   
</p>
<p>In order to tie to an expected bill for a month, select the date range for that month. Our billing is based on a tiered structure, so broader date ranges might not reflect the invoice properly because we bill monthly.
</p>
<p>Note: Because Actual Spend and Reclaimed Spend are displayed in a stacked column, it is often visually helpful to hide one of them to see them in absolute and not relative terms by clicking on them in the key provided.
</p>

<h3 class="title mb-2">Report Definitions</h3>

<h4>Actual Spend</h4>
<p>The Actual Spend shows the dollar amount that was spent on this particular set of search terms for the specific day in question.
</p>
<h4>Reclaimed Spend</h4>
<p>Reclaimed Spend shows the dollar amount that AdAi was able to save from being spent on this particular set of search terms for the specific day in question.  <a rel="noopener noreferrer" target="_blank" href="https://revvim.com/calculating-reclaimed-ad-budget">See how Revvim calculates the Reclaimed Spend</a>.  
</p>
`;

helpContent.adai.conquestedTableTitle = 'AdAi Conquested Search Terms Help';
helpContent.adai.conquestedTableContent = `
<h3 class="title mb-2">What does this report show me?</h3>
<p>This report shows the <a rel="noopener noreferrer" target="_blank" href="https://support.google.com/google-ads/answer/2472708">search terms</a> for which you have a #1 SEO listing that were found to be conquested by a competitor during the last hour.
</p>
<p>If no Search Terms are displaying here, good job!  It means you are protecting your brand from competitors.  If there are search terms listed here, they represent an opportunity to reinvest some of the reclaimed spend.  For example, perhaps you can create a new ad to protect a search term or maybe you increase the budget on an existing ad.</p>

<h3 class="title mb-2">Report Definitions</h3>

<h4>Conquested Search Terms</h4>
<p>A Conquested Search Term is a search term where you have a number one organic listing that is being pushed down by competitor ads with no ad of your own for some portion of the day.</p>

<h4>Total Monthly Impressions</h4>
<p>This shows the number of impressions as per Google Search Console over the last 28 days for this search term.</p>
`;

helpContent.adai.activityLogTitle = 'AdAi Activity Log Help';
helpContent.adai.activityLogContent = `
<h3 class="title mb-2">Report Definitions</h3>

<h4>Search Term</h4>
<p>These are the <a rel="noopener noreferrer" target="_blank" href="https://support.google.com/google-ads/answer/2472708">search terms</a> for which you have a #1 SEO listing where AdAi was able to suppress your ad because there were no competitors present.  This list does not represent all the search terms AdAi may be monitoring, but only those for which AdAi was able to action.</p>

<h4>Action</h4>
<p>There are three actions that AdAi can take:</p>
<ol>
<li>Added to Negative Keyword List – when a search term is added to the negative keyword list, your ad is suppressed (not turned off or disabled).</li>
<li>Removed from Negative Keyword list – the search term is removed from the negative keyword list so that your ad displays.</li>
<li>Cost-Rebasing Started / Ended – occasionally we will allow a search term to display even when it is uncontested.  We do this to ensure that we have the most recent and accurate CTR and CPC data for the search terms, values that are used in our <a rel="noopener noreferrer" target="_blank" href="https://revvim.com/calculating-reclaimed-ad-budget">Reclaimed Ad Spend</a> calculations.</li>
</ol>
<h4>Date</h4>
<p>This shows the date and time that the action was taken.</p>
`;

export default helpContent;
