import prodNames from "@/util/prodNames";

export const Dashboards = [
  {
    path: "/app/admin/dashboards/:dashboardId/permissions",
    name: "app.admin.dashboard.permission",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-dashboard", "admin-widgets"],
      breadCrumb(route) {
        const dashboardId = route.params.dashboardId;
        return [
          {
            text: "Home",
            to: {name: "app-home"},
          },
          {
            text: "Admin",
            to: {name: "app.admin"},
          },
          {
            text: "Dashboards",
            to: {name: "app.admin.dashboard"},
          },
          {
            text: dashboardId,
            to: { name: "app.admin.dashboards.dashboard.detail" },
          },
          {
            text: "Permissions",
            to: {name: "app.admin.dashboard.permission"},
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-dashboardsDashboards" */ "@/views/admin/dashboard/permission/PermissionsView.vue"
        )
  },
  {
    path: "/app/admin/dashboards/:dashboardId",
    name: "app.admin.dashboard.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-dashboard", "admin-widgets"],
      breadCrumb(route) {
        const dashboardId = route.params.dashboardId;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Admin",
            to: { name: "app.admin" },
          },
          {
            text: "Dashboards",
            to: { name: "app.admin.dashboard" },
          },
          {
            text: dashboardId,
            to: { name: "app.admin.dashboards.dashboard.detail" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-dashboards-detail" */ "@/views/admin/dashboard/DashboardDetail.vue"
        ),
  },
  {
    path: "/app/admin/dashboards",
    name: "app.admin.dashboard",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-dashboard", "admin-widgets"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: {name: "app-home"},
          },
          {
            text: "Admin",
            to: {name: "app.admin"},
          },
          {
            text: "Dashboards",
            to: {name: "app.admin.dashboard"},
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-dashboardsDashboards" */ "@/views/admin/dashboard/DashboardsView.vue"
        )
  }
];
