export default {
  REVVIM: "Revvim",
  ADAI: "AdAi",
  CONTENTSPARK: "ContentSpark",
  REACT: "ReACT"
};

export function productLabelById(id) {
  switch (id) {
    case 2:
    case "2":
      return "Fraud Ai";
    case 3:
    case "3":
      return "ReACT";
    case 1:
    case "1":
      return "Ad Ai";
  }
}
