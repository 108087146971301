import axios from "axios";

export const apiUrl = import.meta.env.VITE_API_URL;
export const apiEventLog = {
    async getPropertiesEventLog(propertyId) {
        this.apiIsLoading = true;
        let result;
        result = await axios.post(
            `${apiUrl}/auth/remote/manage/system/changelog/test`, {
                UserId: this.$store.user.userId,
                PropertyId: propertyId
            }
        );
        result = result.data;
        this.apiIsLoading = false;
        return result;
    },
    async getPropertySettingsEventLog(propertyId) {
        this.apiIsLoading = true;
        let result;
        result = await axios.get(
            `${apiUrl}/auth/admin/properties/${propertyId}/eventlog/settingsChangeLog.json`
        );
        result = result.data;
        this.apiIsLoading = false;
        return result;
    },

}
