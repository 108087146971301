import prodNames from "@/util/prodNames";

export const PartnerCs = [
  {
    path: "/app/cs/partners/:partnerId/payments/:paymentId",
    name: "app.cs.partner.payment.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'cs',
      permission: ["admin-partner", "admin-partner-read"],
      breadCrumb(route) {
        const partnerId = route.params.partnerId;
        const paymentId = route.params.paymentId;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Customer Success",
            to: { name: "app.cs" },
          },
          {
            text: "Partners",
            to: { name: "app.cs.partner" },
          },
          {
            text: partnerId,
            to: { name: "app.cs.partner.detail" },
          },
          {
            text: "Payments",
            to: { name: "app.cs.partner.detail" },
          },
          {
            text: paymentId,
            to: { name: "app.cs.partner.payment.detail" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-partners-payments-detail" */ "@/views/accounting/partners/payment/PaymentDetail"
      ),
  },
  {
    path: "/app/cs/partners/:partnerId/view/:view",
    name: "app.cs.partner.detail.view",
    meta: {
      requiresAuth: true,
      department: 'cs',
      prodName: prodNames.REVVIM,
      permission: ["admin-partner", "admin-partner-read"],
      breadCrumb(route) {
        const partnerId = route?.params?.partnerId;
        const view = route?.params?.view;
        return [
          {
            text: "Home",
            to: {
              name: "app-home",
            },
          },
          {
            text: "Customer Service",
            to: {
              name: "app.cs",
            },
          },
          {
            text: "Partners",
            to: {
              name: "app.cs.partner",
            },
          },
          {
            text: partnerId,
            to: {
              name: "app.cs.partner.detail",
            }
          },
          {
            text: view,
            to: {
              name: "app.cs.partner.detail.view",
            },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-partners-detail" */ "@/views/accounting/partners/PartnerDetailTabs"
        ),
  },
  {
    path: "/app/cs/partners/:partnerId",
    name: "app.cs.partner.detail",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'cs',
      permission: ["admin-partner", "admin-partner-read"],
      breadCrumb(route) {
        const partnerId = route.params.partnerId;
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Customer Success",
            to: { name: "app.cs" },
          },
          {
            text: "Partners",
            to: { name: "app.cs.partner" },
          },
          {
            text: partnerId,
            to: { name: "app.cs.partner.detail" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-partners-detail" */ "@/views/accounting/partners/PartnerDetailTabs"
      ),
  },
  {
    path: "/app/cs/partners",
    name: "app.cs.partner",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      department: 'cs',
      permission: ["admin-partner", "admin-partner-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Customer Success",
            to: { name: "app.cs" },
          },
          {
            text: "Partners",
            to: { name: "app.cs.partner" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-partners" */ "@/views/accounting/partners/PartnerView"
      ),
  },
];
