import prodNames from "@/util/prodNames";

export const Core=[
    {
        path: "/",
        name: "home",
        redirect: { name: "app.login" },
        meta: {
            prodName: prodNames.REVVIM
        }
    },
    {
        path: "/login/:mode?",
        props: true,
        name: "app.login",
        meta: {
            prodName: prodNames.REVVIM
        },
        component: () => import(/* webpackChunkName: "Core-login" */ "@/views/Login.vue")
    },
    {
        path: "/logout",
        name: "app.logout",
        meta: {
          prodName: prodNames.REVVIM
        },
        component: () => import(/* webpackChunkName: "Core-login" */ "@/views/Login.vue")
    },
    {
        path: "/partner-data-request",
        redirect: "/app/partnerPortal/data"
    },
    /**
     * Authenticated Routes
     */
    {
        path: "/app/welcome",
        name: "app.productMenu",
        meta: {
            requiresAuth: true,
            prodName: prodNames.REVVIM
        },
        component: () => import(/* webpackChunkName: "Core-productPicker" */ "@/views/home/HomeDashboard.vue")
    },
    {
        path: "/app/agree-terms",
        name: "app.termsAgree",
        meta: {
            requiresAuth: true,
            prodName: prodNames.REVVIM
        },
        component: () => import(/* webpackChunkName: "Core-terms" */ "@/views/TermsAgree.vue")
    },
    {
        path: "/app/oauthcallback",
        name: "app.googleOauthCallback",
        meta: {
            requiresAuth: true,
            prodName: prodNames.REVVIM
        },
        props: true,
        component: () => import(/* webpackChunkName: "Core-oAuth" */  "@/views/GoogleOauthCallback.vue")
    },
]
