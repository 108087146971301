/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import { createVuetify } from 'vuetify'
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import i18n from './i18n'
import { useI18n } from 'vue-i18n'
import * as components from 'vuetify/components'
import { VTreeview } from 'vuetify/labs/VTreeview'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: 'light',
  },
  locale: {
    //@ts-ignore
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
  defaults: {
    VTextField: { variant: 'underlined' },
    VTabs: { color: 'primary' },
    VAutocomplete: { variant: 'underlined' },
    VCombobox: { variant: 'underlined' },
    VProgressLinear: { color: 'primary' }
  },
  components: {
    ...components,
    VTreeview
  }
})
