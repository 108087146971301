import {Agent} from "@/routes/admin/Agent";
import {Cron} from "@/routes/admin/Cron";
import {Dashboards} from '@/routes/admin/Dashboards'
import {Domain} from "@/routes/admin/Domain";
import {Onboards} from "@/routes/admin/Onboard";
import {User} from "@/routes/admin/User";
import {Widget} from "@/routes/admin/Widgets";

import prodNames from "@/util/prodNames";

export const Admin = [
  {
    path: "/app/admin",
    name: "app.admin",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
     permission: [
       "admin-property",
       "admin-property-read"
     ],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: {
              name: "app-home",
            },
          },
          {
            text: "Admin",
            to: {
              name: "app.admin",
            },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-agents" */ "@/views/admin/AdminDashboard"
      ),
  },
  {
    path: "/app/admin/group",
    name: "app.admin.group",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: "admin-user-group",
      breadCrumb() {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Admin",
            to: { name: "app.admin" },
          },
          {
            text: "Groups",
            to: { name: "app.admin.group" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-groups" */ "@/views/admin/groups/GroupsView"
      ),
  },
  {
    path: "/app/admin/email/history",
    name: "app.admin.email.history",
    meta: {
      requiresAuth: true,
      prodName: prodNames.REVVIM,
      permission: ["admin-cron-history", "admin-email-history-read"],
      breadCrumb() {
        return [
          {
            text: "Home",
            to: { name: "app-home" },
          },
          {
            text: "Admin",
            to: { name: "app.admin" },
          },
          {
            text: "Email History",
            to: { name: "app.admin.email.history" },
          },
        ];
      },
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Admin-emailHistory" */ "@/views/admin/email/EmailHistoryView.vue"
      ),
  },
  ...Agent,
  ...Cron,
  ...Dashboards,
  ...Domain,
  ...Onboards,
  ...Widget,
  ...User,
];
