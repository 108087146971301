export const organizationDetailViews = [
  {
    icon: "mdi-chart-bar",
    path: 'dashboard',
    label: "Dashboard",
    permission: ["admin-organizations"]
  },
  {
    path: 'mcc',
    icon: "mdi-finance",
    label: "MCC Opportunities",
    permission: ["admin-organizations", "admin-organizations-read"]
  },
  {
    icon: "mdi-briefcase-account",
    path: 'clients',
    label: "Organization Clients",
    permission: ["admin-organizations", "admin-organizations-read"]
  },
  {
    icon: "mdi-information",
    label: "General",
    path: 'general',
    permission: ["admin-organizations", "admin-organizations-read"]
  },
  {
    path: 'users',
    icon: "mdi-account-circle",
    label: "Users",
    permission: ["admin-organizations", "admin-organizations-read"]
  },
  {
    icon: "mdi-shield-account",
    path: 'agents',
    label: "Agents",
    permission: ["admin-organizations", "admin-organizations-read"]
  },
  {
    path: 'customer',
    icon: "mdi-account-tie",
    label: "Customer Info",
    permission: ["admin-organizations", "admin-organizations-read"]
  },
  {
    path: 'partner',
    icon: "mdi-account-supervisor-circle",
    label: "Partner Info",
    permission: ["admin-organizations", "admin-organizations-read"]
  }
]
