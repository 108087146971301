/**
 * router/index.ts
 */

import {createRouter, createWebHistory} from 'vue-router'
import {Routes} from "@/routes";
import {useAppStore} from '@/stores/app';
import {getCurrentUser} from 'aws-amplify/auth';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  // extendRoutes: setupLayouts,
  routes: [
    ...Routes
  ],
  scrollBehavior() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  },
});

const getAuthUser = async (store) => {
  if (store.signedIn) {
    try {
      //Is the current session valid?
      const user = await getCurrentUser();
      return true;
    } catch (error) {
      //getCurrentUser throws is no session is found
      return false;
    }
  }
};

//Gets required steps for users
//or shortcuts
//let x=0;
const getRedirect = (to, from, store) => {
  //if(x==0) store.commit('setCognitoAttributes', {'custom:termsAgreeDate':'20190101'}); x++;

  let redirect = false;
  let user = store.cognito.attributes;
  let userTermsUpdated = true;

  //TOS is up to date?
  if (
    !user["custom:termsAgreeDate"] ||
    parseInt(user["custom:termsAgreeDate"]) <
    parseInt(import.meta.env.VITE_TERMS_DATE)
  ) {
    let {name, fullPath, query, params, hash} = to;
    redirect = {
      name: "app.termsAgree",
      params: {goNext: {name, fullPath, query, params, hash}}
    };
    userTermsUpdated = false;
  }

  //Already logged in but going to login page
  if (to.name === "app.login") {
    redirect = {name: "app-home"};
  }

  if (redirect.name === to.name) {
    redirect = false;
  }

  return redirect;
};

router.beforeEach(async (to, from, next) => {
  const store = useAppStore();
  let redirect,
    isAuthUser = await getAuthUser(store);

  if (to.meta.requiresAuth) {
    // await store.restored;

    if (!isAuthUser) {
      //User is not logged in
      let {name, fullPath, query, params, hash, meta} = to;
      store.setPreLoginRoute({
        name,
        fullPath,
        query,
        params,
        hash,
        meta
      });
      // Calling logout instead of login to
      // make sure the session is completely reset
      // User will be redirected to login after that
      next({ name: "app.logout" });
    } else {
      // is there specific permission for the route
      // if so check impersonated or authenticated access to the route
      if (
        to.meta.permission &&
        store &&
        !(
          store.hasPermission(to.meta.permission) ||
          store.hasAuthUserPermission(to.meta.permission) ||
          store.hasAuthUserPermission("host")
        )
      ) {
        // debugger;
        next({name: "app-home"});
      }

      //User is logged in - do they need to complete
      //any steps first? Any shortcuts needed?
      redirect = getRedirect(to, from, store);
    }

    if (redirect) {
      next(redirect);
    } else {
      let {name, fullPath, query, params, hash} = from;
      store.setPrevRoute({name, fullPath, query, params, hash});
      store.setLastSelectedProduct(to.meta.prodName);
      next();
    }
  }
  else {
    //Auth not required for this view
    let {name, fullPath, query, params, hash} = from;
    store.setPrevRoute({name, fullPath, query, params, hash});

    if (isAuthUser && to.name === "app.login") {
      next({name: "app-home"});
    }
    else {
      next();
    }
  }
});

router.onError(error => {
  if (error.name && error.name === "ChunkLoadError") {
    if (localStorage.getItem("lastChunkError") !== error.message) {
      // Todo show modal that new version of the app is available and they need to refresh
      // Save the last error to avoid an infinite reload loop if the chunk really does not exists after reload
      localStorage.setItem("lastChunkError", error.message);
      location.reload(true);
    } else {
      // Todo: alert user that they are offline
      // The chunk really does not exist after reload
      console.log("Error: Could not load chunk");
    }
  }
});

// Reload if cannot load dynamic import
window.addEventListener('vite:preloadError', (event) => {
  console.log("vite:preloadError");
  window.location.reload()
});

export default router
