import axios from "axios";
import {EventBus} from "@/event";

export const apiUrl = import.meta.env.VITE_API_URL;
export const apiKeywordTartgeting = {
  async getPropertiesKeywordTargets(propertyId, params) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/properties/${propertyId}/keywordtargets/targets.json`,
      params
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updatePropertiesKeywordTargets(propertyId, payload) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/admin/properties/${propertyId}/keywordtargets/targets.json`,
      payload
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Updated"});
    this.apiIsLoading = false;
    return result;
  },
  async addPropertiesKeywordForced(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/properties/${propertyId}/keywordtargets/forced/keyword.json`
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Updated"});
    this.apiIsLoading = false;
    return result;
  },
  async addPropertiesKeywordExcluded(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/properties/${propertyId}/keywordtargets/foexcludedrced/keyword.json`
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Updated"});
    this.apiIsLoading = false;
    return result;
  },
  async removePropertiesKeywordForced(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.delete(
      `${apiUrl}/auth/admin/properties/${propertyId}/keywordtargets/forced/keyword.json`
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Updated"});
    this.apiIsLoading = false;
    return result;
  },
  async removePropertiesKeywordExcluded(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.delete(
      `${apiUrl}/auth/admin/properties/${propertyId}/keywordtargets/excluded/keyword.json`
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Updated"});
    this.apiIsLoading = false;
    return result;
  },
}
