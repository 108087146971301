import axios from "axios";
import {EventBus} from "@/event";
export const apiUrl = import.meta.env.VITE_API_URL;
export const apiAdGroups = {
  async getAdGroupsForProperty(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/${propertyId}/adgroups/adGroups.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updatePropertyAdGroupCollectingData(propertyId, assignment) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/admin/properties/${propertyId}/adgroups/react/dataCollection/action.json`,
      assignment
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Ad Group Updated"});
    this.apiIsLoading = false;
    return result;
  },
  async getPropertyAdGroupSearchTerms(propertyId, adGroupId, pct) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/${propertyId}/adgroups/${adGroupId}/searchTerms.json?pct=${pct}`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPropertyAdGroupSearchAds(propertyId, adGroupId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/${propertyId}/adgroups/${adGroupId}/ads.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPropertyAdGroupLabels(propertyId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/${propertyId}/labels.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async addPropertyAdGroupRule(propertyId, rule) {
    this.apiIsLoading = true;
    let result;
    result = await axios.post(
      `${apiUrl}/auth/admin/properties/${propertyId}/adgroups/rules/rule.json`,
      rule
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Ad Group Rule Created"});
    this.apiIsLoading = false;
    return result;
  },
  async getPropertyAdGroupRules(propertyId, adGroupId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/${propertyId}/adgroups/${adGroupId}/rules.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPropertyAdGroupRuleAvailableAds(propertyId, adGroupId, ruleId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/${propertyId}/adgroups/${adGroupId}/rules/${ruleId}/availableAds.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async getPropertyAdGroupsRuleAdStatus(propertyId, adGroupId, ruleId) {
    this.apiIsLoading = true;
    let result;
    result = await axios.get(
      `${apiUrl}/auth/admin/properties/${propertyId}/adgroups/${adGroupId}/rules/${ruleId}/AdStatus.json`
    );
    result = result.data;
    this.apiIsLoading = false;
    return result;
  },
  async updatePropertyAdGroupRuleEnabled(propertyId, assignment) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/admin/properties/${propertyId}/adgroups/rules/rule_enabled.json`,
      assignment
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Ad Group Rule Updated"});
    this.apiIsLoading = false;
    return result;
  },
  async updatePropertyAdGroupRule(propertyId, assignment) {
    this.apiIsLoading = true;
    let result;
    result = await axios.put(
      `${apiUrl}/auth/admin/properties/${propertyId}/adgroups/rules/rule.json`,
      assignment
    );
    result = result.data;
    EventBus.$emit("snackbar", {text: "Ad Group Rule Updated"});
    this.apiIsLoading = false;
    return result;
  },

}
